import React, { useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ReactGA from 'react-ga';
import { useLocation } from "react-router-dom";

import HomeHeader from '../layout/home/HomeHeader';
import HomeSearch from '../layout/home/HomeSearch';
import HomeLinksContainer from '../layout/home/HomeLinksContainer';
import HomeFormsContainer from '../layout/home/HomeFormsContainer';
import BackToTop from '../layout/home/BackToTop';
import HomeBackground from '../layout/home/HomeBackground';
import Footer from '../layout/general/Footer.js';

import ShareIcon from '@material-ui/icons/Share';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import InsertChartIcon from '@material-ui/icons/InsertChart';
import LanguageIcon from '@material-ui/icons/Language';
import BuildIcon from '@material-ui/icons/Build';
import PhotoFilterIcon from '@material-ui/icons/PhotoFilter';
import GroupIcon from '@material-ui/icons/Group';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import LaunchIcon from '@material-ui/icons/Launch';
import SchoolIcon from '@material-ui/icons/School';
import PolicyIcon from '@material-ui/icons/Policy';
import ControlCameraIcon from '@material-ui/icons/ControlCamera';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import ForumIcon from '@material-ui/icons/Forum';

import AssignmentIcon from '@material-ui/icons/Assignment';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

import bric_logo from '../../images/BRIC_logo_2_dark.png';

const useStyles = makeStyles((theme) => ({
  homescreen: {
    width: '100%',
    position: 'relative',
    zIndex: 1
  },
  homeContainerRoot: {
    width: '100%',
    background: 'rgb(0,66,106)',
    background: 'linear-gradient(165deg, rgba(0,66,106,0.2) 20%, rgba(0,66,106,0.4) 100%)'
  },
  breakContain: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  breakLine: {
    width: '45%',
    height: '0px',
    border: '2px solid #0092d6',
    borderRadius: '1px'
  }
}));

export default function Home() {
  const classes = useStyles();
  const location = useLocation();
  const topRef = useRef(null);
  const linksRef = useRef(null);
  const formsRef = useRef(null);

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  // Links
  const links = [
    {
      name: 'Research TeamShare',
      description: 'Research organizational hub.',
      link: 'http://teamshare/patient/research/default.aspx',
      icon: (<ShareIcon color='action' fontSize='large'/>),
      internal: true,
      order: 1
    },
    {
      name: 'REDCap - Nemours',
      description: 'Online survey & database tool.',
      link: 'https://redcap.nemoursresearch.org/redcap/',
      icon: (<InsertChartIcon color='action' fontSize='large'/>),
      internal: false,
      order: 2
    },
    {
      name: 'PEDSnet - Nemours',
      description: 'National pediatric healthcare community.',
      link: 'https://apps.nemoursresearch.org/pedsnet/',
      icon: (<LanguageIcon color='action' fontSize='large'/>),
      internal: true,
      order: 3
    },
    {
      name: 'DE-CTR ACCEL',
      description: 'Delaware research resources.',
      link: 'https://www.de-ctr.org/',
      icon: (<PhotoFilterIcon color='action' fontSize='large'/>),
      internal: false,
      order: 4
    },
    {
      name: 'DelawareINBRE',
      description: 'Delaware biomedical research network.',
      link: 'http://de-inbre.org/',
      icon: (<GroupIcon color='action' fontSize='large'/>),
      internal: false,
      order: 5
    },
    {
      name: 'IRBNet',
      description: 'Compliance & research management.',
      link: 'https://www.irbnet.org/release/index.html',
      icon: (<PlaylistAddCheckIcon color='action' fontSize='large'/>),
      internal: false,
      order: 6
    },
    {
      name: 'Intent to Pursue',
      description: 'Research project declaration & evaluation.',
      link: 'http://teamshare/nemourswide/grantsgifts/Lists/IntenttoPursue/My%20Intent%20to%20Pursue.aspx',
      icon: (<LaunchIcon color='action' fontSize='large'/>),
      internal: true,
      order: 7
    },
    {
      name: 'CITI Training',
      description: 'Research ethics & compliance training.',
      link: 'https://about.citiprogram.org/en/homepage/',
      icon: (<SchoolIcon color='action' fontSize='large'/>),
      internal: false,
      order: 8
    },
    {
      name: 'Policy Tech',
      description: 'Policy & procedure management.',
      link: 'https://nemours.policytech.com/',
      icon: (<PolicyIcon color='action' fontSize='large'/>),
      internal: false,
      order: 9
    },
    {
      name: 'NBR Intranet Site',
      description: 'Nemours Biomedical Research hub.',
      link: 'https://nemoursonline.sharepoint.com/sites/clinical/SitePages/Biomedical-Research.aspx',
      icon: (<ControlCameraIcon color='action' fontSize='large'/>),
      internal: true,
      order: 10
    },
  ];

  // Forms
  const forms = [
    {
      name: 'Capital Equipment Request',
      description: 'Request for all equipment purchases greater than $2,500.',
      link: 'https://redcap.nemoursresearch.org/redcap/surveys/?s=HMECADJJT4',
      icon: (<AddShoppingCartIcon color='action' fontSize='large'/>),
      internal: false,
      order: 1
    },
    {
      name: 'NBR Travel Request',
      description: 'Request funds for travel or virtual conference costs.',
      link: 'https://redcap.nemoursresearch.org/redcap/surveys/?s=XMT3AEM7N8',
      icon: (<FlightTakeoffIcon color='action' fontSize='large'/>),
      internal: false,
      order: 2
    },
    {
      name: 'Nemours PEDSnet Request',
      description: 'Request PEDSnet data for Nemours Associates.',
      link: 'https://redcap.nemoursresearch.org/redcap/surveys/?s=8X83AWMHHY',
      icon: (<LanguageIcon color='action' fontSize='large'/>),
      internal: false,
      order: 3
    },
    {
      name: 'PEDSnet ATLAS Access Request',
      description: 'Request access for Nemours installation of ATLAS.',
      link: 'https://redcap.nemoursresearch.org/redcap/surveys/?s=J3C3HJHAXW',
      icon: (<LanguageIcon color='action' fontSize='large'/>),
      internal: false,
      order: 4
    },
    {
      name: 'REDCap Account Request',
      description: 'Request Nemours REDCap instance account.',
      link: 'https://redcap.nemoursresearch.org/redcap/surveys/?s=LT7J9H74AK',
      icon: (<AssignmentIcon color='action' fontSize='large'/>),
      internal: false,
      order: 5
    },
    {
      name: 'Research Account Request',
      description: 'Request Nemours Research compute account.',
      link: 'https://redcap.nemoursresearch.org/redcap/surveys/?s=L4HNE7JAMN',
      icon: (<AssignmentIcon color='action' fontSize='large'/>),
      internal: false,
      order: 6
    },
    {
      name: 'Research Assoc. Onboarding Tool',
      description: 'Manager aid for associates joining Research or externally funded projects.',
      link: 'https://redcap.nemoursresearch.org/redcap/surveys/?s=8JNM3C48A9',
      icon: (<SupervisorAccountIcon color='action' fontSize='large'/>),
      internal: false,
      order: 7
    },
    {
      name: 'Research Communication Request',
      description: 'Request for research communications to be included in Nemours Now.',
      link: 'https://redcap.nemoursresearch.org/redcap/surveys/?s=YDENM8NLNC44AEFT',
      icon: (<ForumIcon color='action' fontSize='large'/>),
      internal: false,
      order: 8
    },
    {
      name: 'Supplemental Funding Request',
      description: 'Request supplimental funding for a research project at Nemours.',
      link: 'https://redcap.nemoursresearch.org/redcap/surveys/?s=TPJETXKT9X',
      icon: (<MonetizationOnIcon color='action' fontSize='large'/>),
      internal: false,
      order: 9
    },
  ];

  // Departments
  const departments = [
    {
      name: 'BRIC',
      description: 'Biomedical Research Informatic Center',
      link: '/bric',
      logo: '',
      order: 1
    },
  ];

  // Handle scroll to reference on page
  const scrollToRefs = (ref) => {
    ref.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
  };


  return (
    <div className={classes.homeContainerRoot}>
      <HomeBackground />
      <div className={classes.homescreen} ref={topRef}>
        <HomeHeader
          scrollToRefs={scrollToRefs}
          linksRef={linksRef}
          formsRef={formsRef}
          logo={bric_logo}
          />
        <HomeSearch
          links={links}
          forms={forms}
          departments={departments} />
        <div ref={linksRef}>
          <HomeLinksContainer
            links={links} />
        </div>
        <div ref={formsRef}>
          <HomeFormsContainer
            forms={forms} />
        </div>
        <BackToTop
          scrollToRefs={scrollToRefs}
          topRef={topRef}
        />
        <Footer />
      </div>
    </div>

  );
}

//
