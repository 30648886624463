import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import ResourceSection from '../../utility/resources/ResourceSection';
import ResourceSearch from '../../utility/resources/ResourceSearch';

import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles((theme) => ({
  deptResourcesContainer: {
    width: '100%'
  },
  resourceContainer: {
    width: '100%',
    padding: '30px 2%',
    boxShadow: '0px 0px 10px 10px #d3d3d3',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
  head: theme.deptProjectsHeader,
}));

export default function DepartmentResources(props) {
  const classes = useStyles();
  const [view, setView] = useState(0);
  // const [width, setWidth] = useState(props.resources.length * 390);
  var content = '';
  var resource = '';
  // const containerWidth = { width: width }

  if (view === 0){
    content = (
      <div>
        {props.resources.map((resource, index) => (
          <ResourceSection
            name={resource.name}
            id={resource.id}
            items={resource.items}
            key={index} />
        ))}
      </div>
    );
  } else {
    resource = props.resources.filter(resource => resource.id == view)[0];
    content = '';
  }


  return (
    <div className={classes.deptResourcesContainer}>
      <div className={classes.head}>
        <Typography
          variant='h4'
          color='primary' >
          Resources
        </Typography>
      </div>
      <div className={classes.resourceContainer}>
        <ResourceSearch
          resources={props.resources} />
        { content }
      </div>
    </div>
  );
}
