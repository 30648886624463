import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Typography, Button, Paper, TableContainer, Table, TableBody, TableRow, TableCell } from '@material-ui/core';

import header_image from '../../../images/nsurp/header_graphic_2.png';
import ProjectList from '../../../files/2022_NSURP_ProjectDescriptionsList.pdf';


const useStyles = makeStyles((theme) => ({
  announcementContainer: {
    width: '100%',
  },
  announcementContent: {
    textAlign: 'left',
    padding: '0px 20px 20px 20px',
  },
  twoCols: {
    width: '100%',
    textAlign: 'left',
    marginBottom: '30px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  cols: {
    width: '45%',
    margin: '0px',
    padding: '20px',
    flexGrow: 1,
    minWidth: '350px',
  },
  table: {
    minWidth: 300,
    maxWidth: 800,
  },
  tableContainer: {
    width: '100%',
    padding: '20px 0px',
    display: 'flex',
    justifyContent: 'center',
  },
  tablePaper: {
    maxWidth: 800,
  },
  infoParagraph: {
    marginBottom: '20px',
  },
  buttonRow: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginBottom: '20px',
  },
  buttonRowContain: {
    width: '50%',
    minWidth: '300px',
    padding: '10px',
  },
  deptIntroHeroContain:{
    width: '100%',
    maxHeight: '280px',
    overflowY: 'hidden',
    marginBottom: '20px',
  },
}));

export default function Home(props) {
  const classes = useStyles();

  const timeline = [
    {
      event: '2023 Application opens',
      date: 'December 1, 2022',
    },
    {
      event: '2023 Application closes',
      date: 'January 15, 2023 at 11:59 pm EST',
    },
    {
      event: 'All letters of recommendation due',
      date: 'January 31, 2023',
    },
    {
      event: 'Positions filled',
      date: 'Mid-March 2023',
    },
    {
      event: 'Program begins',
      date: 'May 30, 2023',
    },
    {
      event: 'Program ends',
      date: 'August 4, 2023',
    },
  ];

  return (
    <div className={classes.announcementContainer}>
      <div className={classes.deptIntroHeroContain}>
        <img src={header_image} width='100%' height='auto'/>
      </div>
      <Typography
        variant='h2'
        color='primary' >
        Nemours Summer Undergraduate Research Program (NSURP)
      </Typography>
      <div className={classes.announcementContent}>
        <Typography
          variant='body1'
          color='secondary'
          className={classes.infoParagraph}
          >
          Every summer, the Nemours Biomedical Research Department provides funding for undergraduate students to work on clinical, laboratory and translational research projects. The Nemours Summer Undergraduate Research Program is a 10-week, full-time program that runs from late May through early August. The available positions are located at four Nemours sites:
        </Typography>
        <div className={classes.twoCols}>
          <ul className={classes.cols}>
            <li><strong>Nemours Children's Hospital, Delaware</strong> in Wilmington, DE. Occasionally from the DE site, there are projects based at Thomas Jefferson University in Philadelphia, PA.</li>
            <li><strong>Nemours Children's Health, Jacksonville</strong> in Jacksonville, FL</li>
            <li><strong>Nemours Children's Hospital, Florida</strong> in Orlando, FL</li>
            <li><strong>Nemours Children's Health, Pensacola</strong> in Pensacola, FL</li>
          </ul>
          <iframe className={classes.cols} width="100%" height="315" src="https://www.youtube.com/embed/f1oXBpAy0fo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <Typography
          variant='h4'
          color='primary' >
          Application Timeline
        </Typography>
        <div className={classes.tableContainer}>
          <TableContainer component={Paper} className={classes.tablePaper}>
            <Table className={classes.table} >
              <TableBody>
                {timeline.map((row) => (
                  <TableRow key={row.event}>
                    <TableCell component="th" scope="row">
                      {row.event}
                    </TableCell>
                    <TableCell align="right">{row.date}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <Typography
          variant='h4'
          color='primary' >
          Application
        </Typography>
        <Typography
          variant='body1'
          color='secondary'
          className={classes.infoParagraph}
          >
          The application is electronic and submitted online. Click 'Apply' in the left side menu to open the application.
        </Typography>
        <Typography
          variant='h4'
          color='primary' >
          Scholarship
        </Typography>
        <Typography
          variant='body1'
          color='secondary'
          className={classes.infoParagraph}
          >
          Each NSURP scholar receives a $5,000 scholarship.
        </Typography>
        <Typography
          variant='h4'
          color='primary' >
          Eligibility
        </Typography>
        <div className={classes.announcementContent}>
          <ul>
            <li>United States citizen or Permanent Resident</li>
            <li>Enrollment as an undergraduate student at the time of application</li>
            <li>Minimum 3.2 college GPA</li>
            <li>Provide proof of COVID-19 vaccination</li>
            <li>Interest in research motivated by problems in pediatric health</li>
          </ul>
        </div>
        <Typography
          variant='h4'
          color='primary' >
          Selection Process
        </Typography>
        <Typography
          variant='body1'
          color='secondary'
          className={classes.infoParagraph}
          >
          During the online application process, applicants are asked to rank up to five of their top project choices. The location of the project is noted on the Project Abstract list. Scholarship recipients are matched with scientist/physician mentors according to both a student's expressed interest in the projects and their background, and selection of the student by the mentors.
        </Typography>
        <Typography
          variant='h4'
          color='primary' >
          Program Activities
        </Typography>
        <Typography
          variant='body1'
          color='secondary'
          className={classes.infoParagraph}
          >
          Enrichment activities integral to the program include research/clinical seminars, participation in student-led journal club, and other activities. At the conclusion of the program, a symposium is held during which Summer Research Students present their research activities to the Nemours community, family, and friends.
        </Typography>
        <Typography
          variant='h4'
          color='primary' >
          Project Abstracts
        </Typography>
        <div className={classes.buttonRow}>
          <div className={classes.buttonRowContain}>
            <Typography
              variant='body1'
              color='secondary'
              >
              NSURP Project Abstract list:
            </Typography>
          </div>
          <div className={classes.buttonRowContain}>
            <Typography
              variant='body1'
              color='secondary'
            >
              REFER TO NSURP APPLICATION FOR CURRENT PROJECT ABSTRACTS
            </Typography>
          </div>
        </div>
        <div className={classes.buttonRow}>
          <div className={classes.buttonRowContain}>
            <Typography
              variant='body1'
              color='secondary'
              >
              For more information on Nemours Biomedical Research:
            </Typography>
          </div>
          <div className={classes.buttonRowContain}>
            <Button color="primary" target="__blank__" href="https://www.nemours.org/pediatric-research.html">Nemours Research Website</Button>
          </div>

        </div>
        <div className={classes.buttonRow}>
          <div className={classes.buttonRowContain}>
            <Typography
              variant='body1'
              color='secondary'
              >
              Direct questions to:
            </Typography>
          </div>
          <div className={classes.buttonRowContain}>
            <Button color="primary" href="mailto:summerresearch@nemours.org">Program Coordinator</Button>
          </div>
        </div>
      </div>
    </div>
  );
}
