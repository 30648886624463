import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import profile from '../../../images/profile-icon.png';


const useStyles = makeStyles((theme) => ({
  personPaper: {
    margin: '10px',
    cursor: 'pointer'
  },
  personContainer: {
    width: '300px',
    height: '300px',
    padding: '28px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  personImgContain: {
    width: '170px',
    height: '170px',
    marginBottom: '14px',
    display: 'flex',
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center'
  },
  personImg: {

  },
  personTextContain: {
    width: '100%'
  }
}));

export default function Person(props) {
  const classes = useStyles();
  var pic = props.uid ? "https://www.nemoursresearch.org/snap/sites/default/files/imagecache/user_image_large/pictures/picture-" + props.uid + ".jpg" : '';

  const addDefaultSrc = (ev, pic) => {
    ev.target.src = pic;
  }

  const [ele, setEle] = useState(6);

  const toggleRaise = (val, fxn) => {
    if (val == 6) {
      fxn(18);
    } else {
      fxn(6);
    }
  };


  return (
    <Paper
      className={classes.personPaper}
      elevation={ele}
      onMouseOver={() => toggleRaise(ele, setEle)}
      onMouseOut={() => toggleRaise(ele, setEle)}>
      <div
        className={classes.personContainer}
        onClick={() => {props.scrollToRefs(props.peopleRef); props.setView(props.uid)}}
      >
        <div className={classes.personImgContain}>
          <img className={classes.personImg} src={pic} alt={props.name} height='170' onError={(e) => addDefaultSrc(e, props.pic)}/>
        </div>
        <div className={classes.personTextContain}>
          <Typography
            variant='h6'
            color='primary'
            align='center'>
            { props.name }
          </Typography>
          <Typography
            variant='body2'
            color='secondary'
            align='center'>
            { props.title }
          </Typography>
        </div>
      </div>
    </Paper>
  );
}
