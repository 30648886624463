import React, { useRef, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import DepartmentIntro from './DepartmentIntro';
import DepartmentAbout from './DepartmentAbout';
import DepartmentProjects from './DepartmentProjects';
import DepartmentApps from './DepartmentApps';
import DepartmentPeople from './DepartmentPeople';
import DepartmentResources from './DepartmentResources';
import Footer from '../general/Footer.js';

import Typography from '@material-ui/core/Typography';
import { Grid, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

import InfoIcon from '@material-ui/icons/Info';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import PeopleIcon from '@material-ui/icons/People';
import FindInPageIcon from '@material-ui/icons/FindInPage';

const useStyles = makeStyles((theme) => ({
  deptPageRoot: {
    minHeight: '100vh',
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap'
  },
  deptPageContainer: {
    width: '100%',
    maxWidth: '1600px',
    overflow: 'hidden',
    display: 'flex',
    flexWrap: 'wrap'
  },
  deptSideBar: {
    width: '100%',
    padding: '40px 20px'
  },
  deptMenuBold: {
    fontWeight: 700
  },
  deptContent: {
    width: '100%',
    minWidth: '330px',
    height: '100vh',
    overflowY: 'scroll',
    overflowX: 'hidden'
  },
  timelineContain: {
    marginTop: '30px'
  },
  deptSection: {
    width: '100%',
    paddingLeft: '2%',
    paddingRight: '16px',
    marginBottom: '48px'
  }
}));

export default function DepartmentPage(props) {
  const classes = useStyles();
  const introRef = useRef(null);
  const aboutRef = useRef(null);
  const projectsRef = useRef(null);
  const appsRef = useRef(null);
  const peopleRef = useRef(null);
  const resourcesRef = useRef(null);

  const menuIcons = [(<InfoIcon />), (<AccountTreeIcon />), (<SettingsApplicationsIcon />), (<PeopleIcon />), (<FindInPageIcon />)];
  const refList = [introRef, aboutRef, projectsRef, appsRef, peopleRef, resourcesRef]; //, resourcesRef
  const idList = ['intro', 'about', 'projects', 'applications', 'people', 'resources']; //, 'resources'
  const [top, setTop] = useState(0);
  const [width, setWidth] = React.useState(window.innerWidth);
  const [showMenu, setShowMenu] = React.useState(1);
  const listBold = {primary: classes.deptMenuBold};
  var resources = '';


  const scrollToRef = (ref) => {
    ref.current.scrollIntoView({ block: 'start',  behavior: 'smooth' });
  };

  const handleScroll = (event) => {

    var ind = idList.length - 1;
    while (ind >= 0) {

      if (ind == 0){
        setTop(ind);
        ind = -1;
      } else {
        var element = document.getElementById(idList[ind]).getBoundingClientRect().bottom - 100;
        var prevElement = document.getElementById(idList[ind-1]).getBoundingClientRect().bottom - 100;
        if (element < 0){
          setTop(ind);
          ind = -1;
        } else if (element > 0 && prevElement < 0) {
          setTop(ind - 1);
          ind = -1;
        }
      }
      ind --;
    }
  };

  const handleResize = () => {
    setWidth(window.innerWidth);
  };

  window.addEventListener('resize', handleResize);

  useEffect(() => {
    document.getElementById('dept-content').addEventListener('scroll', handleScroll);
    if (width <= 960 && showMenu == 1){
      setShowMenu(0);
    } else if (width > 960 && showMenu == 0){
      setShowMenu(1);
    }
  });

  const menuList = (
    <List>
      {['About', 'Projects', 'Applications', 'People', 'Resources'].map((text, index) => ( //, 'Resources'
        <ListItem
          button
          onClick={() => scrollToRef(refList[index+1])}
          key={text}
          selected={top == index ? true : false}>
          <ListItemIcon>{ menuIcons[index] }</ListItemIcon>
          <ListItemText
            primary={text}
            classes={top == index ? listBold : {}}/>
        </ListItem>
      ))}
    </List>
  );

  var menu = (
    <div className={classes.deptSideBar}>
      <Typography
        variant='h3'
        color='primary'
        align='left'
      >
        { props.acronym }
      </Typography>
      <Typography
        variant='h6'
        color='secondary'
        align='left'
      >
        { props.name }
      </Typography>
      <hr />
      { menuList }
    </div>
  );

  if (showMenu == 0){
    menu = '';
  }

  return (
    <div className={classes.deptPageRoot}>
      <div className={classes.deptPageContainer}>
        <Grid container>
          <Grid item xs={12} md={3} lg={2}>
            { menu }
          </Grid>
          <Grid item xs={12} md={9} lg={10}>
            <div className={classes.deptContent} id='dept-content'>
              <div className={classes.timelineContain}>
                <div className='timeline-container'>
                  <div id='intro' ref={introRef} className={classes.deptSection}>
                    <DepartmentIntro
                      name={props.name}
                      logo={props.logo}
                      acronym={props.acronym}
                      pic={props.pic}/>
                  </div>
                </div>
                <div className='timeline-container'>
                  <div id='about' ref={aboutRef} className={classes.deptSection}>
                    <DepartmentAbout
                      content={props.about}/>
                  </div>
                </div>
                <div className='timeline-container'>
                  <div id='projects' ref={projectsRef} className={classes.deptSection}>
                    <DepartmentProjects
                      projects={props.projects}/>
                  </div>
                </div>
                <div className='timeline-container'>
                  <div id='applications' ref={appsRef} className={classes.deptSection}>
                    <DepartmentApps
                      apps={props.apps}/>
                  </div>
                </div>
                <div className='timeline-container'>
                  <div id='people' ref={peopleRef} className={classes.deptSection}>
                    <DepartmentPeople
                      people={props.people}/>
                  </div>
                </div>
                <div className='timeline-container timeline-container-last'>
                  <div id='resources' ref={resourcesRef} className={classes.deptSection}>
                    <DepartmentResources
                      resources={props.resources}/>
                  </div>
                </div>
                <Footer />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
