import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import applicationPreview from '../../../files/2023_NSURP_Application_Preview.pdf';


const useStyles = makeStyles((theme) => ({
  applyContainer: {
    width: '100%',
  },
  applyContent: {
    textAlign: 'left',
    padding: 20,
  },
  infoParagraph: {
    marginBottom: '20px',
  },
  buttonRow: {
    width: '100%',
    margin: '20px 0px 20px 0px',
    textAlign: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
  },
  applyButton: {
    flexGrow: 1,
    minWidth: '200px',
    margin: '10px',
  }
}));


// WHEN APPLICATION IS READY
// <div className={classes.applyContent}>
//   <Button
//     variant='outlined'
//     href="https://redcap.nemoursresearch.org/redcap/surveys/?s=HLF3NXDARD"
//     target='__blank__'
//   >
//     2022 NSURP Application
//   </Button>
// </div>


export default function Apply(props) {
  const classes = useStyles();

  return (
    <div className={classes.applyContainer}>
      <Typography
        variant='h2'
        color='primary' >
        Apply
      </Typography>
      <div className={classes.applyContent}>
        <Typography
          variant='body1'
          color='secondary'
          className={classes.infoParagraph}
        >
          Thank you for your interest in the Nemours Summer Undergraduate Research Program! The program application will be open from December 1, 2022 to January 15, 2023. For questions, contact <a href="mailto:summerresearch@nemours.org">summerresearch@nemours.org</a>.
        </Typography>
        <Typography
          variant='body1'
          color='secondary'
          align='left'
          className={classes.infoParagraph}
        >
          The Application Preview provides a look at important information and questions that require personal responses. <strong>DO NOT SUBMIT THIS AS FORMAL APPLICATION FOR THE SCHOLARSHIP</strong>. The official application can be found by clicking “Apply Now”.
        </Typography>
        <div className={classes.buttonRow}>
          <Button
            className={classes.applyButton}
            variant='outlined'
            color='secondary'
            href={applicationPreview}
            target='__blank__'
          >
            Application Preview
          </Button>
          <Button
            className={classes.applyButton}
            variant='contained'
            color='primary'
            href="https://redcap.nemoursresearch.org/redcap/surveys/?s=EKJRHP4WFDENC3N7"
            target='__blank__'
          >
            Apply Now
          </Button>
        </div>

      </div>


    </div>
  );
}
