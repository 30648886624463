import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import ApplicationPreview from '../../../files/NSURP_Application_PREVIEW.docx';


const useStyles = makeStyles((theme) => ({
  overviewContainer: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap'
  },
  overviewContent: {
    textAlign: 'left',
    padding: '20px',
  },
  infoParagraph: {
    marginBottom: '20px',
  },
  colContainer: {
    width: '100%',
    paddingBottom: '20px',
    display: 'flex',
    flexWrap: 'wrap',
  },
  col: {
    width: '50%',
    minWidth: '300px',
  },
  buttonRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
  },
}));

export default function Overview(props) {
  const classes = useStyles();

  return (
    <div className={classes.overviewContainer}>
      <Typography
        variant='h2'
        color='primary' >
        Overview
      </Typography>
      <div className={classes.overviewContent}>
        <Typography
          variant='body1'
          color='secondary'
          align='left'
          className={classes.infoParagraph}
        >
          Since 1971, the Nemours Biomedical Research Department has been funding scholarships to undergraduate students for work on summer research projects through the Nemours Summer Undergraduate Research Program (NSURP). Recipients of this award are paid a scholarship for the 10-week, full-time program.
        </Typography>
        <Typography
          variant='body1'
          color='secondary'
          align='left'
          className={classes.infoParagraph}
        >
          Each year, the program is comprised of an assortment of laboratory-based and applied/clinical research projects. Scholarship recipients are matched with scientist/physician mentors according to student's expressed interest in the projects, the student's background, and selection of the student by the mentors.
        </Typography>
        <Typography
          variant='body1'
          color='secondary'
          align='left'
          className={classes.infoParagraph}
        >
          Areas of study encompass a wide variety of medical specialties, including but not limited to the list below:
        </Typography>
        <div className={classes.colContainer}>
          <div className={classes.col}>
            <ul>
              <li>Balance</li>
              <li>Cardiology</li>
              <li>Genetics</li>
              <li>Hearing</li>
              <li>Immunology</li>
              <li>Medical Imaging</li>
              <li>Metabolic Disease</li>
              <li>Musculoskeletal Diseases</li>
              <li>Neonatology</li>
            </ul>
          </div>
          <div className={classes.col}>
            <ul>
              <li>Neurology</li>
              <li>Oncology</li>
              <li>Orthopedics</li>
              <li>Pulmonology</li>
              <li>Radiology</li>
              <li>Reproductive Biology</li>
              <li>Rheumatology</li>
              <li>Speech</li>
              <li>Tissue Engineering</li>
            </ul>
          </div>
        </div>
        <Typography
          variant='h4'
          color='primary' >
          Goal & Purpose
        </Typography>
        <Typography
          variant='body1'
          color='secondary'
          align='left'
          className={classes.infoParagraph}
        >
          The Nemours Summer Undergraduate Research Program (NSURP) seeks to provide a well-rounded, healthcare-focused experience in biomedical research to undergraduates interested in pursuing advanced studies in the biological sciences and/or health professions.
        </Typography>
        <Typography
          variant='body1'
          color='secondary'
          align='left'
          className={classes.infoParagraph}
        >
          During the 10-week program, participants will learn about human subjects protection and animal welfare concerns, research ethics, evidence-based medicine, and clinical and translational research, while performing research in the context of pediatric medicine. Nemours research faculty guide students through the process of formulating and testing hypotheses, interpreting data, and communicating results. Mentors regularly meet with students throughout the program and provide assistance in experimental design and interpretation of collected data.
        </Typography>
        <Typography
          variant='h4'
          color='primary' >
          Scholarship
        </Typography>
        <Typography
          variant='body1'
          color='secondary'
          align='left'
          className={classes.infoParagraph}
        >
          Each NSURP scholar will receive a $5,000 scholarship, dispersed in two installments during the program.
        </Typography>
        <Typography
          variant='body1'
          color='secondary'
          align='left'
          className={classes.infoParagraph}
        >
          The program does not provide a housing allowance. While the program can provide information about housing options in the area, finding and securing suitable housing is the scholar's responsibility.
        </Typography>
        <Typography
          variant='body1'
          color='secondary'
          align='left'
          className={classes.infoParagraph}
        >
          Travel from one's residence to the research site is the responsibility of the student. There is ample free parking available at all locations except in Philadelphia.
        </Typography>
        <Typography
          variant='h4'
          color='primary' >
          Program Activities
        </Typography>
        <div className={classes.announcementContent}>
          <ul>
            <li>Research Seminars</li>
            <li>Journal Club</li>
            <li>Discussions about medical and academic careers</li>
            <li>Research presentations by scholars</li>
          </ul>
        </div>
        <Typography
          variant='body1'
          color='secondary'
          align='left'
          className={classes.infoParagraph}
        >
          All students will present their research project and findings to the Nemours community via poster sessions and oral presentations. Family and friends are invited to attend these events.
        </Typography>
        <Typography
          variant='body1'
          color='secondary'
          align='left'
          className={classes.infoParagraph}
        >
          Shadowing opportunities may be available but are not facilitated or managed by the Summer Program.
        </Typography>
        <Typography
          variant='h4'
          color='primary' >
          Application
        </Typography>
        <Typography
          variant='h6'
          color='primary' >
          Eligibility Requirements
        </Typography>
        <div className={classes.announcementContent}>
          <ul>
            <li>United States citizen or Permanent Resident</li>
            <li>Enrollment as an undergraduate student at the time of application</li>
            <li>Minimum 3.2 college GPA</li>
            <li>Provide proof of COVID-19 vaccination</li>
            <li>Interest in research motivated by problems in pediatric health</li>
          </ul>
        </div>
        <Typography
          variant='h6'
          color='primary' >
          Application Requirements
        </Typography>
        <div className={classes.announcementContent}>
          <ul>
            <li>Completed application form</li>
            <li>Resume</li>
            <li>Two letters of recommendation
              <ul>
                <li>The people writing your letters will receive an email via the application with directions on how to submit a recommendation on your behalf.</li>
                <li>Letters should be from college faculty members who can speak to your experiences working on research and/or your academic abilities; one must be from a science faculty member. For freshman, it is acceptable if one of these letters is from a high school science teacher.</li>
                <li>Letters from mentors in research programs you were involved with are preferable.</li>
            </ul>
            </li>
            <li>Transcripts
              <ul>
                <li>The program will accept "unofficial" transcripts as long as they are legible and complete.</li>
              </ul>
            </li>
            <li>Short essay responses (listed within the application)</li>
            <li>Optional: two additional supplementary files (poster, writing sample, etc.)</li>
          </ul>
        </div>
        <Typography
          variant='body1'
          color='secondary'
          align='left'
          className={classes.infoParagraph}
        >
          We encourage diversity in our student cohort: we welcome applicants from all races, ethnicities, sexual orientations, and abilities/disabilities to apply.
        </Typography>
        <div className={classes.buttonRow}>
          <Typography
            variant='body1'
            color='secondary'
            >
            Direct questions to:
          </Typography>
          <Button color="primary" href="mailto:summerresearch@nemours.org">Program Coordinator</Button>
        </div>
      </div>
    </div>
  );
}
