import React, { useRef, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Home from './Home';
import Overview from './Overview';
import FAQ from './FAQ';
import Gallery from './Gallery';
import YearInReview from './YearInReview';
import Contact from './Contact';
import Apply from './Apply';
import Footer from '../general/Footer.js';

import { Typography, Grid, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

import HomeIcon from '@material-ui/icons/Home';
import InfoIcon from '@material-ui/icons/Info';
import HelpIcon from '@material-ui/icons/Help';
import PhotoIcon from '@material-ui/icons/Photo';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';

import header_image from '../../../images/nsurp/small_header_2022.png';


const useStyles = makeStyles((theme) => ({
  nsurpPageRoot: {
    minHeight: '100vh',
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap'
  },
  deptPageContainer: {
    width: '100%',
    maxWidth: '1600px',
    overflow: 'hidden',
    display: 'flex',
    flexWrap: 'wrap'
  },
  deptSideBar: {
    width: '100%',
    padding: '40px 20px'
  },
  deptMenuBold: {
    fontWeight: 700
  },
  deptContent: {
    width: '100%',
    minWidth: '330px',
    height: '100vh',
    overflowY: 'scroll',
    overflowX: 'hidden'
  },
  timelineContain: {
    marginTop: '30px'
  },
  deptSection: {
    width: '100%',
    paddingLeft: '2%',
    paddingRight: '16px',
    marginBottom: '48px'
  },
  footer: {
    width: '100%',
    padding: '20px 80px'
  },
  headerImg:{
    width: '100%',
    maxHeight: '280px',
    overflowY: 'hidden'
  },
  footerText: {
    marginBottom: 0,
  },
}));

export default function NsurpPage(props) {
  const classes = useStyles();
  const homeRef = useRef(null);
  const overviewRef = useRef(null);
  const faqRef = useRef(null);
  const galleryRef = useRef(null);
  const contactRef = useRef(null);
  const applyRef = useRef(null);

  const menuIcons = [(<HomeIcon />), (<InfoIcon />), (<HelpIcon />), (<PhotoIcon />), (<ContactMailIcon />), (<AssignmentTurnedInIcon />)];
  const refList = [homeRef, overviewRef, faqRef, galleryRef, contactRef, applyRef];
  const idList = ['home', 'overview', 'faq', 'gallery', 'contact', 'apply'];
  const [top, setTop] = useState(0);
  const [width, setWidth] = React.useState(window.innerWidth);
  const [showMenu, setShowMenu] = React.useState(1);
  const listBold = {primary: classes.deptMenuBold};
  var resources = '';


  const scrollToRef = (ref) => {
    ref.current.scrollIntoView({ block: 'start',  behavior: 'smooth' });
  };

  const handleScroll = (event) => {

    var ind = idList.length - 1;
    while (ind >= 0) {

      if (ind == 0){
        setTop(ind);
        ind = -1;
      } else {
        var element = document.getElementById(idList[ind]).getBoundingClientRect().bottom - 100;
        var prevElement = document.getElementById(idList[ind-1]).getBoundingClientRect().bottom - 100;
        if (element < 0){
          setTop(ind);
          ind = -1;
        } else if (element > 0 && prevElement < 0) {
          setTop(ind);
          ind = -1;
        }
      }
      ind --;
    }
  };

  const handleResize = () => {
    setWidth(window.innerWidth);
  };

  window.addEventListener('resize', handleResize);

  useEffect(() => {
    document.getElementById('dept-content').addEventListener('scroll', handleScroll);
    if (width <= 960 && showMenu == 1){
      setShowMenu(0);
    } else if (width > 960 && showMenu == 0){
      setShowMenu(1);
    }
  });

  const menuList = (
    <List>
      {['Home', 'Overview', 'FAQ', 'Year in Review', 'Contact', 'Apply'].map((text, index) => ( //, 'Resources'
        <ListItem
          button
          onClick={() => scrollToRef(refList[index])}
          key={text}
          selected={top == index ? true : false}>
          <ListItemIcon>{ menuIcons[index] }</ListItemIcon>
          <ListItemText
            primary={text}
            classes={top == index ? listBold : {}}/>
        </ListItem>
      ))}
    </List>
  );

  var menu = (
    <div className={classes.deptSideBar}>
      <div className={classes.headerImg}>
        <img src={header_image} width='100%' height='auto'/>
      </div>
      <Typography
        variant='h3'
        color='primary'
        align='left'
      >
        { props.acronym }
      </Typography>
      <Typography
        variant='h6'
        color='secondary'
        align='left'
      >
        { props.name }
      </Typography>
      <hr />
      { menuList }
    </div>
  );

  if (showMenu == 0){
    menu = '';
  }

  return (
    <div className={classes.nsurpPageRoot}>
      <div className={classes.deptPageContainer}>
        <Grid container>
          <Grid item xs={12} md={3} lg={2}>
            { menu }
          </Grid>
          <Grid item xs={12} md={9} lg={10}>
            <div className={classes.deptContent} id='dept-content'>
              <div className={classes.timelineContain}>
                <div className='timeline-container'>
                  <div id='home' ref={homeRef} className={classes.deptSection}>
                    <Home />
                  </div>
                </div>
                <div className='timeline-container'>
                  <div id='overview' ref={overviewRef} className={classes.deptSection}>
                    <Overview />
                  </div>
                </div>
                <div className='timeline-container'>
                  <div id='faq' ref={faqRef} className={classes.deptSection}>
                    <FAQ />
                  </div>
                </div>
                <div className='timeline-container'>
                  <div id='gallery' ref={galleryRef} className={classes.deptSection}>
                    <YearInReview />
                  </div>
                </div>
                <div className='timeline-container'>
                  <div id='contact' ref={contactRef} className={classes.deptSection}>
                    <Contact />
                  </div>
                </div>
                <div className='timeline-container'>
                  <div id='apply' ref={applyRef} className={classes.deptSection}>
                    <Apply />
                  </div>
                </div>
                <div className={classes.footer}>
                  <Typography
                    className={classes.footerText}
                    variant='body2'
                    color='secondary'
                    align='center'
                  >
                    Nemours Biomedical Research, a division of The Nemours Foundation, operates NemoursResearch.org and medsci.udel.edu.org and medsci.udel.edu in collaboration with the University of Delaware. All content is © Nemours Biomedical Research and The Nemours Foundation unless otherwise noted.
                  </Typography>
                </div>
                <Footer />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
