import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';


const useStyles = makeStyles((theme) => ({
  backToTopContainer: {
    width: '100%',
    paddingBottom: '40px',
    display: 'flex',
    justifyContent: 'center'
  },
}));

export default function BackToTop(props) {
  const classes = useStyles();

  return (
    <div className={classes.backToTopContainer}>
      <Button onClick={() => props.scrollToRefs(props.topRef)}>Back to top</Button>
    </div>
  );
}
