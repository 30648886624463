import React, { Fragment, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ReactGA from 'react-ga';
import { useLocation } from "react-router-dom";

import DepartmentPage from '../layout/departments/DepartmentPage';

import data_requests from '../../files/data_requests.pdf';
import suzanne from '../../images/profiles/4.png';
import cara from '../../images/profiles/cara.png';
import bric_logo from '../../images/BRIC_logo_2_dark.png';

const useStyles = makeStyles((theme) => ({
  deptContainerRoot: {
    minHeight: '100vh',
    width: '100%',
    position: 'relative',
    overflow: 'hidden'
  },
}));

export default function Bric() {
  const classes = useStyles();
  const location = useLocation();

  const headPic = 'aiD-hospital';

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  const aboutInfo = (
    <Fragment>
      <p>The Nemours Biomedical Research Informatics Center (BRIC) (formerly Bioinformatics Core facility) provides consultation, training and computational resources (both hardware and software) to Nemours Biomedical Research investigators from both Delaware and Florida.</p>
      <p>BRIC is located at the Nemours/Alfred I. duPont Hospital for Children in Wilmington, Delaware. The main facilities in Rockland Center I occupy about 1000 square feet of work space on two adjacent floors including four offices, one workstation cubicle, and a shared conference room equipped with videoconferencing capability. An additional 273 sq. ft. computer and network facility room is maintained in the Administration and Research Building. BRIC is wheelchair accessible and barrier-free.</p>
      <p>Services offered by BRIC include:</p>
      <ul>
        <li>Experimental design of parallel, crossover, longitudinal, cross-section and microarray studies.</li>
        <li>Data mining of microarray studies.</li>
        <li>Generalized statistical analysis.</li>
        <li>EHR and PEDSnet data requests and analysis.</li>
        <li>Development of special purpose software and applications.</li>
      </ul>
      <p>Software, Languages, Resources and Tools utilized by BRIC:</p>
      <ul>
        <li><strong>Software:</strong> SPSS, SAS, R, Bioconductor, MeV</li>
        <li><strong>Programming Languages:</strong> Python, PHP, PERL, C, C++, Objective C, Unix Shell, Javascript</li>
        <li><strong>Databases & Technologies:</strong> MySQL, SQLite, PostgreSQL, REDCap, i2b2, Wordpress, JQuery, HTML, XML, JSON</li>
      </ul>
      <p>BRIC maintains a variety of computational resources to support big data, speech analysis, and genomics projects as well as a large number of user-defined clinical research databases. These include:</p>
      <ul>
        <li>A dedicated 8-node 256 CPU process Apache Spark/HPC computing cluster with 3TB of RAM and 30TB of local cluster storage on an Infiniband local network with fast access to a high-performance DDN scalable storage system (currently 750TB scalable to 2.5PB)Big data, speech analysis and genomics projects.</li>
        <li>A RedHat Enterprise Linux (RHEL) dual Power9 based GPU server with 128 hyperthreaded cores, 640GB RAM and 2TB fast SSD storage to our Infiniband-based cluster for deep learning and other high intensity computing tasks.</li>
        <li>A dedicated RHEL web/computing server (48 processes, 256G RAM, 16TB RAID) and PostgreSQL database server (32 processes, 128G RAM, and 11TB RAID) on the protected, internal enterprise network for managing Nemours PEDSnet CDM data and related projects including OHDSI Achilles and ATLAS.</li>
        <li>Seven RHEL Virtual Machines (VMs) in the Nemours/CSX secure enterprise computing cluster, providing computational resources, application support and testing for REDCap, MySQL, and an internally developed research-based timekeeping system.</li>
        <li>The primary web/email/database server for asel.udel.edu and web/database server for NemoursResearch.org with 16 CPU processes, 30GB of RAM and 7TB of disk space and has a dedicated 200mbps (with 1Gbps burst capability) internet connection via the University of Delaware.</li>
        <li>An EMC Data Domain backup system supported by Nemours Enterprise IS providing nightly backups of email and file volumes and additional off-site LTO7 tape storage. All servers are housed in the hospital’s datacenter which is environmentally-controlled with redundant power.</li>
      </ul>
      <p>Download the official BRIC Resources document below for more information on the computational resources available.</p>
    </Fragment>
  );

  const subProjects = [
    {
      name: 'Improving Medication Adherence with Telehealthcare Medication Therapy Management to Change Health Outcomes in Adolescents and Young Adults with Asthma [MATCH]',
      acronym: 'MATCH',
      description: 'BRIC hosts and maintains the REDCap project that is used to distribute surveys to participants and collect data. Using both native REDCap tools and custom software modules, BRIC aims to provide a comprehensive system by which to effectively interact with clinicians and patients.',
      about: 'Improving Medication Adherence with Telehealthcare Medication Therapy Management to Change Health Outcomes in Adolescents and Young Adults with Asthma (MATCH) is a multi-center, randomized parallel group study targeted to an at-risk population of Adolescents and Young Adults (AYA) with uncontrolled asthma who have poor adherence with prescribed Inhaled corticosteroid (ICS) therapy.',
      link1: 'https://clinicaltrials.gov/ct2/show/NCT03978936',
      text1: 'Clinicaltrials.gov page',
    },
    {
      name: 'Asthma Research Study, Use of Mobile Devices and the Internet to Streamline An Asthma Clinical Trial [MICT]',
      acronym: 'MICT',
      description: '',
      about: 'This innovative project is a streamlined clinical trial that will run concurrently with a nearly identical traditional clinical trial, "Long-acting Beta Agonist Step Down Study" (LASST) which will allow for direct comparison of processes and outcomes between the streamlined and traditional approach. Children 12 to 17 years old with asthma will be randomized to participate in this project (streamlined trial) or LASST (traditional trial). In this proposal we will: measure comprehension of study information using an original questionnaire, Research Participant Assessment (developed at Nemours), following a parental permission/assent process delivered over the internet in a dynamic interactive multi-media format (Specific Aim 1); measure the efficiency of participant driven data entry from home into a Research Electronic Data Capture (REDCap) online database using the iPad, and quality of spirometry with the EasyOne Plus handheld meter with remote coaching using the iPad (Specific Aim 2); test whether the streamlined approach has a "trial effect" by comparing the differences in Asthma Control Test (ACT) scores following 12 weeks of study drug treatment in children randomized to this project compared to LASST. We will collect effort reporting data to compare personnel costs between the trials. If this streamlined project lacks a "trial effect" and reduces costs compared to LASST, the methodologies would be generalizable to studies which include adults and other diseases.',
      link1: 'https://clinicaltrials.gov/ct2/show/NCT02061280',
      text1: 'Clinicaltrials.gov page',
      link2: '',
      text2: '',
    },
    {
      name: 'Parental Response Using a Daily Digital Diary to Capture Respiratory Symptoms in Young Children with Recurrent Respiratory Tract Illnesses (American Lung Association [ALA] Novel Digital Diary) [ANDDi]',
      acronym: 'ANDDi',
      description: '',
      about: '',
      link1: '',
      text1: '',
    },
  ];

  const projects = [
    {
      name: 'PEDSnet',
      acronym: 'PEDSnet',
      description: 'BRIC serves as the interface between PEDSnet and the Nemours Children’s Healthcare System. This includes maintaining relationships with the partner institutions, facilitating data transfer from our institution to the national pediatric database, and aiding other entities from within Nemours in participating in the PEDSnet community.',
      about: 'PEDSnet does research to improve the health and lives of children. We are a large, national community of hospitals and healthcare organizations, researchers and clinicians, and patients and families. This community works together to identify the most important research questions that can reduce children\'s suffering and support their healthy development. When we do research, our goal is to conduct it as inexpensively and quickly as possible, while engaging all of our stakeholders in the research process along the way. PEDSnet has formed a multi-specialty network that conducts observational research and clinical trials across multiple children\'s hospital health systems. We are governed by parents and senior leaders in these health systems. PEDSnet has produced reusable and expandable governance, logistical, informatics, regulatory, scientific, and training resources, organized as a Pediatric Research Commons, which can be accessed by anyone with some parts of the Commons accessible only to our members. Across its 8 founding institutions, PEDSnet created a longitudinal data resource that dates back to 2009, cuts across all pediatric diseases, and includes all pediatric specialties.',
      list: '',
      link1: 'https://apps.nemoursresearch.org/pedsnet/',
      text1: 'Nemours PEDSnet Website',
      link2: 'https://pedsnet.org/',
      text2: 'PEDSnet Website',
    },
    {
      name: 'National COVID Cohort Collaborative',
      acronym: 'N3C',
      description: 'As a member of the National COVID Cohort Collaborative, BRIC and the DE-CTR aid in providing COVID-related patient data to an open-source national database and serve as an ambassador for the program to the entire Nemours community. Visit the NIH N3C and the Nemours N3C websites linked below for more information on the initiative and getting involved.',
      about: 'The N3C is a partnership among the NCATS-supported Clinical and Translational Science Awards (CTSA) Program hubs and the National Center for Data to Health (CD2H), with overall stewardship by NCATS. Collaborators will contribute and use COVID-19 clinical data to answer critical research questions to address the pandemic.',
      list: '',
      link1: 'https://nemoursresearch.github.io/N3C/',
      text1: 'Nemours N3C Website',
      link2: 'https://ncats.nih.gov/n3c',
      text2: 'NIH N3C Website',
    },
    {
      name: 'Precision Medicine',
      acronym: 'Precision Medicine',
      description: 'The Nemours Precision Medicine program leverages genetic screening and sequencing, genomics and bioinformatics, gene therapy research and pharmaco-genetics to target specific genetic information about patients and their conditions. Together, physicians and researchers throughout Nemours are working to provide customized solutions to some of the most challenging pediatric diagnoses — and expediting those solutions from the bench to bedside. Benefits include: more efficient diagnoses, more effective treatments, fewer side effects, and reduced costs.',
      about: '',
      list: '',
      link1: '',
      text1: '',
      link2: '',
      text2: '',
    },
    {
      name: 'Other projects',
      acronym: 'Other',
      description: 'BRIC also aids in the development and maintenance of projects for other entities within/associated with Nemours. These include:',
      about: '',
      list: subProjects,
      link1: '',
      text1: '',
      link2: '',
      text2: '',
    },
  ];

  const apps = [
    {
      name: 'REDCap',
      acronym: 'REDCap',
      description: 'BRIC maintains the Nemours distribution of REDCap. Responsibilities include aiding in project setup and management as well as maintaining hosting and compute resources for Nemours sponsored projects.',
      about: 'REDCap is a secure web application for building and managing online surveys and databases. While REDCap can be used to collect virtually any type of data in any environment (including compliance with 21 CFR Part 11, FISMA, HIPAA, and GDPR), it is specifically geared to support online and offline data capture for research studies and operations. The REDCap Consortium, a vast support network of collaborators, is composed of thousands of active institutional partners in over one hundred countries who utilize and support their own individual REDCap systems.',
      link1: 'https://redcap.nemoursresearch.org/redcap/',
      text1: 'Nemours REDCap Instance',
      link2: 'https://www.project-redcap.org/',
      text2: 'Project REDCap',
    },
    {
      name: 'Atlas',
      acronym: 'Atlas',
      description: 'BRIC uses ATLAS to facilitate the transcription and processing of health data in association with PEDSnet as well as other studies conducted within the organization.',
      about: 'ATLAS is the data exploration tool that PEDSnet uses for preparatory to research queries, terminology code-set formation, and exploration of the full PEDSnet database. It is a free, publicly available web-based, open-source software application developed by the OHDSI community. It can be used to perform analyses across one or more observational databases which have been standardized to the OMOP Common Data Model V5 and can facilitate exchange of analysis designs with any other organizations across the OHDSI community who have adopted the same open science community standards and tools.',
      link1: 'https://wlmpedweb001.nemours.org/atlas/',
      text1: 'Nemours ATLAS Instance',
      link2: 'https://pedsnet.org/data/atlas-resources/',
      text2: 'PEDSnet ATLAS Page',
    },
    {
      name: 'TimeKeeper',
      acronym: 'TimeKeeper',
      description: 'TimeKeeper is a web-based effort reporting system compliant with federal standards for non-profit organizations using federal grant funds.',
      about: '',
      link1: 'https://apps.nemoursresearch.org/tk2/',
      text1: 'TimeKeeper',
      link2: '',
      text2: '',
    },
    {
      name: 'Scientist Networking and Profiling',
      acronym: 'SNAP',
      description: 'SNAP serves as a central database of Nemours Research associates and a compilation of resources for people within the department. This includes full professional profiles with NIH-style biosketches, publication listings supported by automated search, and a Research Services Portal (RSP) for managing research study approvals.',
      about: '',
      link1: 'https://www.nemoursresearch.org/snap/',
      text1: 'SNAP',
      link2: '',
      text2: '',
    },
  ];

  const people = [
    {
      name: 'Tim Bunnell, PhD',
      uid: 5,
      title: 'Director, Biomedical Informatics Research Center',
      bio: 'Dr. Bunnell received his Ph.D. in Experimental Psychology from The Pennsylvania State University in 1983, concentrating in human speech perception and the acoustic properties of speech (acoustic phonetics). From 1983 to 1989, he worked as a Research Scientist in the Sensory Communication Research Laboratory (later Center for Auditory and Speech Sciences) at Gallaudet University conducting research on the application of digital speech processing techniques to hearing enhancement, primarily for acoustic hearing aid users. In 1989 he became the director of the Speech Processing Laboratory at the Alfred I. duPont Hospital for Children where his research interests expanded to include text to speech synthesis and speech recognition. Dr. Bunnell is now the director of the Nemours Center for Pediatric Auditory and Speech Sciences (CPASS) and head of the Speech Research Laboratory within the CPASS. Since 2000, he has also served as director of the Nemours Biomedical Research Department\'s Bioinformatics Core. The Bioinformatics Core maintains computing infrastructure for Nemours Biomedical Research, and provides expertise in applications development, biostatistics, data mining, and numerical analysis. Dr. Bunnell\'s primary research interests are in biomedical and clinical applications of speech technology for the diagnosis and remediation of hearing and speech disorders, particularly in pediatric patients.',
      pic: ''
    },
    {
      name: 'Chris Pennington, MS',
      uid: 3,
      title: 'Systems and Database Manager',
      bio: '',
      pic: ''
    },
    {
      name: 'Dan Eckrich',
      uid: 407,
      title: 'Supervisor, Research Applications',
      bio: '',
      pic: ''
    },
    {
      name: 'Suzanne McCahn, PhD',
      uid: 4,
      title: 'Assistant Research Scientist',
      bio: 'Dr. McCahan came to Nemours from Fox Chase Cancer Center where she was a postdoctoral associate. Currently, she is an assistant research scientist in Nemours Biomedical Research. She is also a research assistant professor at Thomas Jefferson Medical College. She and H. Timothy Bunnell, PhD formed the nucleus of the Nemours Bioinformatics Core Facility when it began in 2003. Dr. McCahan\'s research includes use of genomics to understand and treat childhood diseases such as juvenile idiopathic arthritis, cryptorchidism and rare genetic disorders. Currently this includes using microarrays and high throughput sequencing to examine global gene expression, DNA copy number variation and single nucleotide polymorphism. In addition, she engages in development of custom applications for data management management and administrates the Nemours installation of REDCap.',
      pic: suzanne
    },
    {
      name: 'Eileen Antico',
      uid: 355,
      title: 'Research and Reporting Analyst',
      bio: '',
      pic: ''
    },
    {
      name: 'Michael Peck',
      uid: 907,
      title: 'Web developer',
      bio: 'Michael received his BS in Biomedical Engineering at Penn State University in 2019. During his time there, Michael began to experiment with graphic and web design as an outlet by which to combine his passions for the arts and the sciences, culminating in a senior year in which he served as the lead project manager and developer for THON.org – the official website for the student-run philanthropy the Penn State Dance MaraTHON.\n\nFollowing graduation, Michael found a wonderful home in the Research Department at Nemours where he aids in developing and maintaining a variety of research-oriented and administrative applications for the organization. As he continues with his journey at Nemours, Michael strives to continue to expand his skillset as a web developer while helping to drive progress in Nemours Research through the use of cutting-edge technologies and modern design techniques.',
      pic: ''
    },
    {
      name: 'Cara Reedy, PhD',
      uid: 9999,
      title: 'Research Data Scientist',
      bio: 'Dr. Reedy received a PhD in cognitive science from Rensselaer Polytechnic Institute in 2020. Their dissertation research combined diploid genetic algorithms, evolving neural networks, and an agent-based simulation to study how the prevalence of genetic disorders and migration between environments affected the evolution of agents\' mating preferences. As a research data scientist at Nemours, Dr. Reedy applies machine learning techniques to investigate health data.',
      pic: cara
    },

  ];


  const computeResources = [
    {
      name: 'BRIC Computer and Facilities Resources Document',
      description: 'Description of current BRIC facilities and available compute resources.',
      link : 'https://www.nemoursresearch.org/snap/sites/default/files/ResourcesBCF%2020200313.doc',
      newTab: 0,
      download: 0
    },
    {
      name: 'University of Delaware Morris Library',
      description: 'University of Delaware library resources.',
      link : 'https://library.udel.edu/',
      newTab: 1,
      download: 0
    },
    {
      name: 'University of Delaware E-Journal Index',
      description: 'E-Journals and E-Books available through UD',
      link : 'http://cx2ef4jw8j.search.serialssolutions.com/ejp/?libHash=CX2EF4JW8J#/?language=en-US&titleType=JOURNALS&beginPage=0',
      newTab: 1,
      download: 0
    },
    {
      name: 'Awesome Deep Learning and EHRs',
      description: 'List of recent ML papers and EHR data.',
      link : 'https://github.com/hurcy/awesome-ehr-deeplearning',
      newTab: 1,
      download: 0
    },
  ];

  const consultingResources = [
    {
      name: 'Available Services and Pricing',
      description: 'Outline of types of consultation offered and pricing.',
      link : 'https://www.nemoursresearch.org/snap/cores/bcf/services',
      newTab: 1,
      download: 0
    },
    {
      name: 'Data Request',
      description: 'Outline of EPIC data request processes.',
      link : data_requests,
      newTab: 0,
      download: 1
    },
  ];

  const supportResources = [
    {
      name: 'REDCap Support',
      description: 'Support request form for REDCap projects.',
      link : '/requests/REDCap',
      email: 'redcap@nemours.org',
      newTab: 1,
      download: 0
    },
    {
      name: 'TimeKeeper Support',
      description: 'Support request form for TimeKeeper.',
      link : '/requests/TimeKeeper',
      email: 'timekeeper@nemours.org',
      newTab: 1,
      download: 0
    },
    {
      name: 'SNAP Support',
      description: 'Support request form for SNAP.',
      link : '/requests/SNAP',
      email: 'snap@nemours.org',
      newTab: 1,
      download: 0
    },
    {
      name: 'PEDSnet CDM Support',
      description: 'Support request form for PEDSnet.',
      link : '/requests/PEDSnetCDM',
      email: 'redcap@nemours.org',
      newTab: 1,
      download: 0
    },
  ];


  const resources = [
    {
      name: 'Facilities & Compute',
      id: 1,
      items: computeResources
    },
    {
      name: 'Consultation',
      id: 2,
      items: consultingResources
    },
  ];

  return (
    <div className={classes.deptContainerRoot}>
      <DepartmentPage
        name='Biomedical Research Informatics Center'
        acronym='BRIC'
        logo={bric_logo}
        pic={headPic}
        about={aboutInfo}
        projects={projects}
        apps={apps}
        people={people}
        resources={resources}/>
    </div>
  );
}
