import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import AboutSection from '../../utility/sections/AboutSection';
import ListSection from '../../utility/sections/ListSection';

import Typography from '@material-ui/core/Typography';
import { Grid, Tabs, Tab, Box, Button } from '@material-ui/core';
import Fade from '@material-ui/core/Fade';


const useStyles = makeStyles((theme) => ({
  deptProjectsContainer: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap'
  },
  head: theme.deptProjectsHeader,
  deptProjectsTabs: {
    width: '100%'
  },
  deptProjectsTabPanels:{
    width: '100%',
    paddingLeft: '2%'
  },
  tabs: {
    paddingTop: '24px',
    paddingBottom: '24px'
  },
  tab: {
    fontSize: '24px',
    textTransform: 'none',
    paddingTop: '12px',
    paddingBottom: '12px'
  },
  selected: {
    fontWeight: 600,
    backgroundColor: '#0092d614',
  },
  blockQuote: {
    marginLeft: '20px',
    backgroundColor: '#0092d614',
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start'
  },
  tabButton: {
    marginRight: '20px'
  }
}));


function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const classes = useStyles();

  const descriptionFormat = children.description.split('\n').map((str, si) => <p key={si}>{str}</p>);

  var buttons = '';
  var about = '';
  var list = '';

  if (children.link1 !== ''){
    buttons = (
      <div className={classes.buttonContainer}>
        <Button className={classes.tabButton} variant="contained" target="_blank" color="primary" href={children.link1}>{children.text1}</Button>
      </div>
    );
  }

  if (children.about !== ''){
    about = (
      <AboutSection
        about={children.about}
        text={children.text2}
        link={children.link2}/>
    );
  }

  if (children.list !== ''){
    list = (
      <ListSection
        list={children.list}
      />
    );
  }

  return (
    <Fade in={value === index} timeout={900}>
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography
              variant='h5'
              color='primary' >
              { children.name }
            </Typography>
            <hr style={{width: '100%', marginBottom: '15px'}} />
            { descriptionFormat }
            { about }
            { list }
            { buttons }
          </Box>
        )}
      </div>
    </Fade>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}


export default function DepartmentProjects(props) {
  const classes = useStyles();

  const [value, setValue] = React.useState(0);
  const [width, setWidth] = React.useState(window.innerWidth);
  const [tabOrientation, setTabOrientation] = React.useState('vertical');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleResize = () => {
    setWidth(window.innerWidth);
  };

  window.addEventListener('resize', handleResize);

  useEffect(() => {
    if (width <= 1279 && tabOrientation == 'vertical'){
      setTabOrientation('horizontal');
    } else if (width > 1279 && tabOrientation == 'horizontal'){
      setTabOrientation('vertical');
    }
  });

  return (
    <div className={classes.deptProjectsContainer}>
      <div className={classes.head}>
        <Typography
          variant='h4'
          color='primary' >
          Projects
        </Typography>
      </div>
      <hr style={{width: '100%'}} />
      <Grid container>
        <Grid item xs={12} lg={2}>
          <div className={classes.deptProjectsTabs}>
            <Tabs
              orientation={tabOrientation}
              variant="scrollable"
              value={value}
              onChange={handleChange}
              className={classes.tabs}
            >
              {props.projects.map((project, index) => (
                <Tab key={index} classes={{root: classes.tab, selected: classes.selected}} label={project.acronym} {...a11yProps(index)} />
              ))}
            </Tabs>
          </div>
        </Grid>
        <Grid item xs={12} lg={10}>
          <div className={classes.deptProjectsTabPanels}>
            {props.projects.map((project, index) => (
              <TabPanel key={index} value={value} index={index}>
                { project }
              </TabPanel>
            ))}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
