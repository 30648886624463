import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

import yearInReview from '../../../files/2022_year_in_review.pdf';

import img1 from '../../../images/nsurp/2022_year_in_review_1.png';
import img2 from '../../../images/nsurp/2022_year_in_review_2.png';

const useStyles = makeStyles((theme) => ({
  yirContainer: {
    width: '100%',
  },
  yirContentContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  yirContent: {
    padding: '20px',
  },
  navRow: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  navButton: {
    width: 100
  },
  downloadRow: {
    width: '100%',
    textAlign: 'center',
  },
  paperContainer1: {
    backgroundImage: `url(${img1})`,
    width: '100%',
    height: '100%',
  },
  paperContainer2: {
    backgroundImage: `url(${img2})`,
    width: '100%',
    height: '100%',
  },
  docPaper: {
    padding: '20px',
  }
}));

export default function Gallery(props) {
  const classes = useStyles();
  const [width, setWidth] = useState(window.innerWidth);
  const [docWidth, setDocWidth] = useState(600);
  const [numPages, setNumPages] = useState(2);
  const [pageNumber, setPageNumber] = useState(1);


  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  const handleResize = () => {
    setWidth(window.innerWidth);
  };

  window.addEventListener('resize', handleResize);

  useEffect(() => {
    if (width <= 500){
      setDocWidth(280);
    } else if (width <= 950){
      setDocWidth(450);
    } else if (width > 1300){
      setDocWidth(600);
    }
  }, [width]);

  return (
    <div className={classes.yirContainer}>
      <Typography
        variant='h2'
        color='primary'
        style={{with: '100%'}} >
        Year in Review
      </Typography>
      <div className={classes.yirContentContainer}>
        <div className={classes.yirContent}>

        <Paper className={classes.docPaper} elevation={4}>
          <img src={pageNumber == 1 ? img1 : img2} width={docWidth}></img>
        </Paper>
          <div className={classes.navRow}>
            <Button
              disabled={pageNumber <= 1}
              onClick={previousPage}
              className={classes.navButton}
            >
              Previous
            </Button>
            <p>
              Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
            </p>
            <Button
              disabled={pageNumber >= numPages}
              onClick={nextPage}
              className={classes.navButton}
            >
              Next
            </Button>
          </div>
          <div className={classes.downloadRow}>
            <Button
              variant='outlined'
              href={yearInReview}
              download
            >
              Download
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
