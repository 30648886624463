import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles((theme) => ({
  personCardContainer: {
    width: '100%',
    padding: '30px',
    display: 'flex',
    flexWrap: 'wrap'
  },
  personCardPaper: {
    padding: '30px',
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'center'
  },
  returnBtnContainer: {
    width: '100%',
  },
  cardContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  card: {
    width: '90%',
    minWidth: '300px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  cardImg: {
    marginBottom: '20px',
    marginTop: '20px'
  }
}));

export default function PersonCard(props) {
  const classes = useStyles();
  var pic = props.person.uid ? "https://www.nemoursresearch.org/snap/sites/default/files/imagecache/user_image_large/pictures/picture-" + props.person.uid + ".jpg" : '';

  const addDefaultSrc = (ev, pic) => {
    ev.target.src = pic;
  }

  return (
    <div className={classes.personCardContainer}>
      <div className={classes.returnBtnContainer}>
        <Button
          color="secondary"
          variant="outlined"
          startIcon={<ArrowBackIcon />}
          onClick={() => {props.scrollToRefs(props.peopleRef); props.setView(0)}}
        >
          Back to people
        </Button>
      </div>
      <div className={classes.cardContainer}>
        <Paper
          className={classes.personCardPaper} elevation={18}>
          <div className={classes.card}>
            <img
              className={classes.cardImg}
              src={pic}
              alt={props.person.name}
              width='200'
              onError={(e) => addDefaultSrc(e, props.person.pic)} />
            <Typography
              variant='h4'
              color='primary'
              align='center'>
              { props.person.name }
            </Typography>
            <Typography
              variant='h6'
              color='primary'
              align='center'
              gutterBottom={true}>
              { props.person.title }
            </Typography>
            <hr style={{width: '70%', marginBottom: '20px'}} />
            <Typography
              variant='body1'
              color='primary'
              align='left'>
              { props.person.bio }
            </Typography>
          </div>
        </Paper>
      </div>
    </div>
  );
}
