import React, { Fragment, useEffect } from 'react';
import { useHistory, useLocation } from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../../../images/horizontal_logo.png';

function getModalStyle() {
  const top = 25;

  return {
    top: `${top}%`,
    margin: 'auto',
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#00426a14',
  },
  logoContain: {
    padding: '6px 6px 6px 0px'
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    textAlign: 'left',
    paddingLeft: '14px',
    borderLeft: '2px solid #309c8a',
  },
  paper: {
    position: 'absolute',
    width: 400,
    textAlign: 'center',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  helpButton: {
    display: 'inline-block',
    width: 'calc(50% - 20px)',
    margin: '10px'
  },
  pagesNav: {
    width: 160,
  },
}));

export default function NavBar(props) {
  const classes = useStyles();
  let history = useHistory();
  var location = useLocation();

  // Help modal
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    if (location.pathname != '/'){
      props.setHomeClass('App Dept');
    } else {
      props.setHomeClass('App Home');
    }
  }, [location]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = () => {
    setOpen(true);
    setAnchorEl(null);
  };

  const handleMenuClick = (link) => {
    history.push(link);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <Typography gutterBottom color='primary' variant="h5" component="h2">
        Help
      </Typography>
      <Typography paragraph color='secondary' component="p">
        This is an internal Nemours portal that provides easy access to a variety of tools and web resources.
      </Typography>
      <Typography paragraph color='secondary' component="p">
        If you are looking for general information on Nemours Biomedical Research, the NBR Intranet site is probably a good place to start.
      </Typography>
      <Button variant='outlined' color='primary' href='https://nemoursonline.sharepoint.com/sites/clinical/SitePages/Biomedical-Research.aspx' className={classes.helpButton}>NBR Intranet</Button>
      <Button variant='outlined' color='close' onClick={()=> { handleClose() }} className={classes.helpButton}>Close</Button>
    </div>
  );

  return (
    <Fragment>
      <div className={classes.root}>
        <AppBar position="static" color="transparent">
          <Toolbar>
            <div className={classes.logoContain}>
              <Button href='/' edge="start" color="inherit" aria-label="menu">
                <img src={logo} width='130px' alt="logo"/>
              </Button>
            </div>
            <Typography variant="h6" color="primary" className={classes.title}>
              Biomedical Research
            </Typography>
            <IconButton
              size="large"
              edge="end"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={handleClick}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={() => handleMenuClick('/bric')}>BRIC</MenuItem>
              <MenuItem component={"a"} href="https://www.nemours.org/pediatric-research/education.html#tabs-89e351a1be-item-9e11886a8e-tab" target="_blank">NSURP</MenuItem>
              <MenuItem onClick={handleOpen}>Help</MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        style={{display:'flex',alignItems:'center',justifyContent:'center'}}
        aria-labelledby="help-modal"
        aria-describedby="help-modal">
        {body}
      </Modal>
    </Fragment>

  );
}
