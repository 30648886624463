import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';


const useStyles = makeStyles((theme) => ({
  homeHeadContainer: {
    width: '100%',
    display: 'block',
    padding: '30px 30px 10px 30px',
    zIndex: 2
  },
  introContain: {
    display: 'block',
    width: '100%',
    minWidth: '330px',
    backgroundColor: '#ffffff80',
    boxShadow: '0 0 65px 40px #ffffff80',
  },
  subheadRow: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  subhead: {
    padding: 10,
  },
  linkStyle: {
    textDecoration: 'underline',
    cursor: 'pointer',
    '&:hover': {
       color: '#309c8a',
       textDecoration: 'underline',
    },
  },
  bricPoweredStyle: {
    padding: 10,
  },
  bricButton: {
    paddingTop: '2px',
    paddingBottom: '2px',
    fontSize: '16px'
  }
}));

export default function HomeHeader(props) {
  const classes = useStyles();

  return (
    <div className={classes.homeHeadContainer}>
      <div className={classes.introContain}>
        <Typography
          variant='h1'
          color='primary'>
          Nemours Research Virtual Hub.
        </Typography>
        <div className={classes.subheadRow}>
          <Typography
            className={classes.subhead}
            variant='h6'
            color='secondary' >
            <strong>Application&nbsp;
              <a className={classes.linkStyle} onClick={() => props.scrollToRefs(props.linksRef)}>links</a>,&nbsp;
              <a className={classes.linkStyle} onClick={() => props.scrollToRefs(props.formsRef)}>forms</a> &&nbsp;
              <a className={classes.linkStyle} onClick={() => props.scrollToRefs(props.formsRef)}>requests</a>
            </strong>.
          </Typography>
          <Typography
            className={classes.bricPoweredStyle}
            variant='body1'
            color='secondary'>
            Maintained by	&nbsp;
            <Button variant='outlined' size='medium' className={classes.bricButton} href='/bric'>
              BRIC
            </Button>
          </Typography>
        </div>
      </div>
    </div>
  );
}
