import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  faqContainer: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap'
  },
  faqContent: {
    width: '100%',
    padding: '20px',
  },
  answerBlock: {
    display: 'block'
  }
}));

export default function FAQ(props) {
  const classes = useStyles();

  const faqs = [
    {
      id: 'program-virtual',
      question: 'Will the program be virtual or in-person?',
      answer: 'The program will include both live and virtual components based on the program being held at multiple sites.',
    },
    {
      id: 'who-eligible',
      question: 'Who is eligible?',
      answer: (
        <ul>
          <li>United States citizen or Permanent Resident</li>
          <li>Enrollment as an undergraduate student at the time of application</li>
          <li>Minimum 3.2 college GPA</li>
          <li>Provide proof of COVID-19 vaccination</li>
          <li>Interest in research motivated by problems in pediatric health</li>
        </ul>
      ),
    },
    {
      id: 'send-letters',
      question: 'How should I send letters of recommendation?',
      answer: (
        <>
          <p>
            Upon completion of your application, each recommender listed will receive an automatically-generated email with a personalized link and instructions on how to submit a letter on your behalf. Recommenders will be asked to answer a few short questions and upload a letter to your application packet directly. <u>It is your responsibility to ensure that your recommender completes this task prior to the January 31st deadline.</u>
          </p>
          <p>An application is considered complete when the application has been submitted and at least two letters of recommendation have been received no later than January 31st. Incomplete applications will not be considered.</p>
        </>

      ),
    },
    {
      id: 'request-letters',
      question: 'From whom should I request the letters of recommendation?',
      answer: 'Letters should be from college faculty members who can speak to your experiences working on research and/or your academic abilities; one must be from a science faculty member. For freshmen, it is acceptable if one of these letters is from a high school science teacher. Letters from mentors in research programs you were involved with are preferable.'
    },
    {
      id: 'transcripts-official',
      question: 'Do the transcripts need to be "official"',
      answer: 'No. The program accepts transcript copies as long as they are legible and complete.'
    },
    {
      id: 'scholarship-amount',
      question: 'What is the scholarship amount?',
      answer: 'Scholars will receive a $5,000 scholarship for the 10-week program, which will be paid in two installments during the summer program.',
    },
    {
      id: 'where-research',
      question: 'Where does the research take place?',
      answer: (
        <>
          <div>Each project is based at one of four Nemours locations that are clearly specified on the project abstract:</div>
          <ul>
            <li><strong>Nemours Children's Hospital, Delaware</strong> in Wilmington, DE. Occasionally from the DE site, there are projects based at Thomas Jefferson University in Philadelphia, PA. Students based in Philadelphia are required to be in Wilmington 1 day/week if the program is not virtual.</li>
            <li><strong>Nemours Children's Health, Jacksonville</strong> in Jacksonville, FL</li>
            <li><strong>Nemours Children's Hospital, Florida</strong> in Orlando, FL</li>
            <li><strong>Nemours Children's Health, Pensacola</strong> in Pensacola, FL</li>
          </ul>
        </>
      ),
    },
    {
      id: 'transport-provided',
      question: 'Is transportation provided to the research site?',
      answer: 'No. Travel from one\'s residence to the research site is up to the student. There is ample free parking at the Nemours locations. If the student is based at Jefferson in Philadelphia, there is a fee for parking.',
    },
    {
      id: 'housing-provided',
      question: 'Is housing provided?',
      answer: 'No. While the program can provide information about housing options in the area, finding suitable housing is ultimately up to each scholar. The program does not provide a housing allowance.',
    },
    {
      id: 'multiple-location',
      question: 'Can I apply to more than one Nemours location?',
      answer: 'Yes, you can apply to one or more than one location. Because you are asked to rank up to five (5) of your top project choices on the application, you may opt to select projects from a variety of locations. Remember that housing and transportation are your responsibility, so you may need to consider location of project in your ranking. On the application, you will be asked about plans for housing and transportation in the area(s) you have chosen.',
    },
    {
      id: 'what-schedule',
      question: 'What is the schedule like?',
      answer: 'The 10-week program is a full-time, 40 hours per week, Monday through Friday commitment, and should be performed in person during general business hours. Specific times will be determined by the project mentors. Attendance is required throughout the entirety of the program. Personal vacation time is not allowed during the 10 weeks.',
    },
    {
      id: 'interviews-application',
      question: 'Are interviews part of the application?',
      answer: 'We cannot interview every applicant. At some point during the selection process, you might be contacted by the program and/or the specific mentor to discuss your application.',
    },
    {
      id: 'when-acceptance',
      question: 'When can I expect to hear about acceptance?',
      answer: 'Notifications will be emailed by mid-March.',
    },
    {
      id: 'more-questions',
      question: 'Who should I contact if I have more questions?',
      answer: (
        <div>Please email the <a href="mailto:summerresearch@nemours.org">Program Coordinator</a> with additional questions.</div>
      ),
    },
  ];


  return (
    <div className={classes.faqContainer}>
      <Typography
        variant='h2'
        color='primary' >
        Frequently Asked Questions
      </Typography>
      <div className={classes.faqContent}>
        {
          faqs.map((faq, fi) => (
            <Accordion key={faq.id}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                id={faq.id}
              >
                <Typography color='secondary' variant='body1'>{faq.question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {
                  (typeof faq.answer === 'string' || faq.answer instanceof String) ?
                  (
                    <Typography color='secondary'>
                      { faq.answer }
                    </Typography>
                  ) :
                  (
                    <div className={classes.answerBlock}>
                      { faq.answer }
                    </div>
                  )
                }
              </AccordionDetails>
            </Accordion>
          ))
        }
      </div>
    </div>
  );
}
