import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#309c8a',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      // light: '#0066ff',
      main: '#383838',
      // dark: will be calculated from palette.secondary.main,
      // contrastText: '#ffcc00',
    },
    error: {
      main: '#E74C3C',
    },
    warning: {
      main: '#E74C3C',
    },
    info: {
      light: '#0092d633',
      main: '#0092d6',
    },
    success: {
      main: '#18BC9C',
    },
    // error: will use the default color
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    // contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    // tonalOffset: 0.2,
  },
  // typography: {
  //   fontFamily: 'Trade Gothic',
  // },
  overrides: {
    // Style sheet name ⚛️
    MuiSvgIcon: {
      // Name of the rule
      colorAction: {
        // Some CSS
        color: '#309c8abf',
      },
      fontSizeLarge: {
        fontSize: '44px'
      },
    },
    MuiTypography: {
      h1: {
        marginBottom: '12px',
        fontSize: '3.5rem'
      },
      h2: {
        marginBottom: '10px',
        fontSize: '3.25rem'
      },
      h3: {
        fontSize: '2.5rem'
      },
      h4: {
        fontSize: '2rem',
        marginBottom: '15px'
      },
      h5: {
        fontSize: '1.65rem',
        marginBottom: '12px',
        lineHeight: 1.1
      },
      h6: {
        fontSize: '1.25rem'
      },
      body2: {
        marginBottom: '20px'
      },
    },
    MuiInputBase: {
      input: {
        fontSize: '16px'
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: '16px'
      },
    },
    MuiButton: {
      root: {
        fontSize: '1rem'
      },
    },
    MuiTableCell: {
      root: {
        fontSize: '1rem'
      },
    },
  },
  // Custom classes
  deptProjectsHeader: {
    width: '100%',
    paddingBottom: '24px'
  },
});

export default theme;
