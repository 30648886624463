import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  resultContainer: {
    width: '100%',
    marginTop: '10px',
    marginBottom: '10px',
    display: 'flex',
    justifyContent: 'center',
    textTransform: 'none',
    textAlign: 'center'
  },
  resultName: {
    width: '35%',
    fontWeight: 'bold',
    borderRight: '2px solid #89cff0',
    paddingRight: 10,
  },
  resultDescription: {
    width: '64%'
  },
}));

export default function SearchResult(props) {
  const classes = useStyles();

  return (
    <Button
      variant='contained'
      className={classes.resultContainer}
      target="_blank"
      href={props.result.link}
      fullWidth>
      <div className={classes.resultName}>{ props.result.name }</div>
      <div className={classes.resultDescription}>{ props.result.description }</div>
    </Button>
  );
}
