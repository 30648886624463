import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import ResourceSearchResults from './ResourceSearchResults.js';

import { Grid, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
  resourceSearchContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap'
  },
  searchBarContain: {
    width: '70%',
    maxWidth: '450px'
  },
  iconContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  searchInput: {
    fontSize: '34px'
  },
  searchResultsContain: {
    width: '100%'
  }
}));

export default function ResourceSearch(props) {
  const classes = useStyles();
  const [view, setView] = useState(0);
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [res1, setRes1] = useState([]);
  const [res2, setRes2] = useState([]);
  const [res3, setRes3] = useState([]);
  var resContent = '';

  // Filter search results
  const searchFilter = (value, set) => {
    var newResults = [];
    newResults = set.filter(item => (
      (item.name.toLowerCase().indexOf(value.toLowerCase()) > -1 || item.description.toLowerCase().indexOf(value.toLowerCase()) > -1 || item.link.toLowerCase().indexOf(value.toLowerCase()) > -1)
    ));
    return newResults;
  }

  // Handle search action
  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    if (e.target.value !== ''){
      // Filter links
      let r1 = searchFilter(e.target.value, props.resources[0].items);
      setRes1(r1);
      // Filter forms
      let r2 = searchFilter(e.target.value, props.resources[1].items);
      setRes2(r2);
      // Filter departments
      let r3 = searchFilter(e.target.value, props.resources[2].items);
      setRes3(r3);
      // Set results open
      setOpen(true);
    } else {
      setRes1([]);
      setRes2([]);
      setRes3([]);
      setOpen(false);
    }
  }

  // Display results if search in progress
  if (searchValue !== ''){
    resContent = (
      <ResourceSearchResults
        name1={props.resources[0].name}
        res1={res1}
        name2={props.resources[1].name}
        res2={res2}
        name3={props.resources[2].name}
        res3={res3}
        open={open} />
    );
  }
  return (
    <div className={classes.resourceSearchContainer}>
      <div className={classes.searchBarContain}>
        <Grid container spacing={1} alignItems="flex-end">
          <Grid item xs={2}>
            <div className={classes.iconContainer}>
              <SearchIcon color='action' fontSize='large'/>
            </div>
          </Grid>
          <Grid item xs={10}>
            <TextField
              id="resource-search"
              label="Search"
              value={searchValue}
              onChange={(e) => handleSearch(e)}
              InputProps={{
                classes: {
                  input: classes.searchInput,
                },
              }}
              fullWidth/>
          </Grid>
        </Grid>
      </div>
      <div className={classes.searchResultsContain}>
        { resContent }
      </div>
    </div>
  );
}
