import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';

import image1 from '../../../images/nsurp/Picture1.png';
import image2 from '../../../images/nsurp/Picture2.png';
import image3 from '../../../images/nsurp/Picture3.png';
import image4 from '../../../images/nsurp/Picture4.png';
import image5 from '../../../images/nsurp/Picture5.png';
import image6 from '../../../images/nsurp/Picture6.png';

const useStyles = makeStyles((theme) => ({
  galleryContainer: {
    width: '100%',
  },
  galleryContent: {
    textAlign: 'left',
    padding: '20px',
  },
  imageList: {
    width: '100%',
    height: '100%',
  },
}));

export default function Gallery(props) {
  const classes = useStyles();
  const [width, setWidth] = useState(window.innerWidth);
  const [cols, setCols] = useState(11);
  const [colTypes, setColTypes] = useState('');
  const [rowHeight, setRowHeight] = useState(300);

  const itemData = [
    {
      img: image1,
      title: '',
      author: '',
      sm: 6,
      md: 7,
      lg: 5,
    },
    {
      img: image3,
      title: '',
      author: '',
      sm: 2,
      md: 3,
      lg: 2,
    },
    {
      img: image2,
      title: '',
      author: '',
      sm: 4,
      md: 5,
      lg: 4,
    },
    {
      img: image4,
      title: '',
      author: '',
      sm: 4,
      md: 5,
      lg: 4,
    },
    {
      img: image5,
      title: '',
      author: '',
      sm: 2,
      md: 3,
      lg: 2,
    },
    {
      img: image6,
      title: '',
      author: '',
      sm: 6,
      md: 7,
      lg: 5,
    },
  ];

  const handleResize = () => {
    setWidth(window.innerWidth);
  };

  window.addEventListener('resize', handleResize);

  useEffect(() => {
    if (width <= 400){
      setCols(6);
      setColTypes('sm');
      setRowHeight(170);
    } else if (width <= 1300){
      setCols(10);
      setColTypes('md');
      setRowHeight(300);
    } else if (width > 1300){
      setCols(11);
      setColTypes('lg');
      setRowHeight(300);
    }
  }, [width]);

  return (
    <div className={classes.galleryContainer}>
      <Typography
        variant='h2'
        color='primary' >
        Gallery
      </Typography>
      <div className={classes.galleryContent}>
        <ImageList rowHeight={rowHeight} className={classes.imageList} cols={cols}>
          {itemData.map((item) => (
            <ImageListItem key={item.img} cols={item[colTypes]}>
              <img src={item.img} alt={item.title} />
            </ImageListItem>
          ))}
        </ImageList>
        <Typography
          variant='body2'
          color='secondary' >
          <em>Photo credit to Cindy Brodoway DE group pictures.</em>
        </Typography>
      </div>
    </div>
  );
}
