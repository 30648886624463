import React, {  } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import SearchResultsSection from '../../utility/search/SearchResultsSection.js';

import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  searchResultsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '30px'
  },
}));

export default function HomeSearchResults(props) {
  const classes = useStyles();
  var title = 'No results';
  var linkResults = '';
  var formResults = '';
  var deptResults = '';

  if (props.links.length >= 1 || props.forms.length >= 1 || props.departments.length >= 1){
    title = 'Results';
  }

  if (props.links.length >= 1){
    linkResults = (
      <SearchResultsSection
        name='Links'
        open={props.open}
        set={props.links}/>
    );
  }

  if (props.forms.length >= 1){
    formResults = (
      <SearchResultsSection
        name='Forms'
        open={props.open}
        set={props.forms}/>
    );
  }

  if (props.departments.length >= 1){
    deptResults = (
      <SearchResultsSection
        name='Departments'
        open={props.open}
        set={props.departments}/>
    );
  }


  return (

      <div className={classes.searchResultsContainer}>
        <Typography variant="h3" color="primary" id='search-results'>
          { title }
        </Typography>
        { linkResults }
        { formResults }
        { deptResults }
      </div>
  );
}
