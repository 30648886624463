import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  resourceButton: {
    height: '180px',
    width: '290px',
    padding: '10px',
    textTransform: 'none',
  },
  resourceHr: {
    width: '80%',
    color: '#309c8a'
  },
  resourceButtonContain: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
  }
}));

export default function ResourceCard(props) {
  const classes = useStyles();

  var link = props.link;
  var tab = '_self';
  var download = false;

  if (props.tab === 1){
    tab = '_blank';
  }

  if (props.download === 1){
    var download = true;
  }

  return (
    <Button
      variant='contained'
      className={classes.resourceButton}
      href={link}
      target={tab}
      download={download}>
      <div className={classes.resourceButtonContain}>
        <Typography color='primary' variant="h6" align='center'>
          { props.name }
        </Typography>
        <hr className={classes.resourceHr}/>
        <Typography color='secondary' component="p" align='center'>
          { props.description }
        </Typography>
      </div>
    </Button>
  );
}
