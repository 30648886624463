import React, { useState } from 'react';

import Particles from 'react-particles-js';


export default function HomeBackground(props) {
  const [view, setView] = useState(0);
  var content = '';

  return (
    <div className='particle-background'>
      <Particles
        params={{
            "particles": {
                "number": {
                    "value": 40
                },
                "size": {
                    "value": 3,
                }
            },
            "interactivity": {
                "events": {
                    "onhover": {
                        "enable": false,
                        "mode": "repulse"
                    }
                }
            }
        }}
        className='particle-container'/>
    </div>
  );
}
