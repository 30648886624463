import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import HomeLink from './HomeLink.js';

const useStyles = makeStyles((theme) => ({
  linkOuterContainer: {
    paddingBottom: '20px'
  },
  linkContainer: {
    height: '100%',
    textAlign: 'center',
    marginTop: '30px',
    padding: '0px 40px 20px 40px',
    backgroundColor: '#ffffffb8',
    boxShadow: '0 0 35px 20px #ffffffb8',
    borderRadius: '10px',
  },
  linkTitle: {
    paddingTop: '15px',
    paddingBottom: '15px',
  }
}));

export default function HomeLinksContainer(props) {
  const classes = useStyles();

  return (
    <div className={classes.linkOuterContainer}>
      <Container className={classes.linkContainer}>
        <Typography variant="h3" color="primary" className={classes.linkTitle} id='links'>
          Helpful links
        </Typography>
        <Grid container spacing={3}>
          {
            props.links.map((link, li) => (
              <Grid item xs={12} sm={6} md={4}>
                <HomeLink
                  name={link.name}
                  description={link.description}
                  link={link.link}
                  icon={link.icon}
                  internal={link.internal} />
              </Grid>
            ))
          }
        </Grid>
      </Container>
    </div>
  );
}
