import React, { useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Person from '../../utility/people/Person';
import PersonCard from '../../utility/people/PersonCard';

import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles((theme) => ({
  deptPeopleContainer: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  head: theme.deptProjectsHeader,
}));

export default function DepartmentPeople(props) {
  const classes = useStyles();
  const peopleRef = useRef(null);
  const [view, setView] = useState(0);
  var content = '';
  var person = '';

  // Handle scroll to reference on page
  const scrollToRefs = (ref) => {
    ref.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
  };

  if (view === 0){
    content = props.people.map((person, index) => (
        <Person
          name={person.name}
          title={person.title}
          bio={person.bio}
          uid={person.uid}
          pic={person.pic}
          key={index}
          setView={setView}
          peopleRef={peopleRef}
          scrollToRefs={scrollToRefs} />
      ));
  } else {
    person = props.people.filter(person => person.uid == view)[0];
    content = (
      <PersonCard
        person={person}
        setView={setView}
        peopleRef={peopleRef}
        scrollToRefs={scrollToRefs} />
    );
  }


  return (
    <div className={classes.deptPeopleContainer} ref={peopleRef}>
      <div className={classes.head}>
        <Typography
          variant='h4'
          color='primary' >
          People
        </Typography>
      </div>
      { content }
    </div>
  );
}
