import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import HomeForm from './HomeForm.js';

const useStyles = makeStyles((theme) => ({
  linkOuterContainer: {
    paddingBottom: '60px'
  },
  linkContainer: {
    height: '100%',
    textAlign: 'center',
    marginTop: '10px',
    padding: '10px 40px 20px 40px',
    backgroundColor: '#ffffffb8',
    boxShadow: '0 0 35px 20px #ffffffb8',
    borderRadius: '10px',
  },
  formTitle: {
    paddingBottom: '15px'
  }
}));

export default function HomeFormsContainer(props) {
  const classes = useStyles();

  return (
    <div className={classes.linkOuterContainer}>
      <Container className={classes.linkContainer}>
        <Typography variant="h3" color="primary" className={classes.formTitle} id='forms'>
          Forms & Requests
        </Typography>
        <Grid container spacing={3} justifyContent='center'>
          {
            props.forms.map((form, fi) => (
              <Grid item xs={12} sm={6} md={4}>
                <HomeForm
                  name={form.name}
                  description={form.description}
                  link={form.link}
                  icon={form.icon}
                  internal={form.internal} />
              </Grid>
            ))
          }
        </Grid>
      </Container>
    </div>
  );
}
