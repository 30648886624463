import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';


const useStyles = makeStyles((theme) => ({
  listSectionContainer: {
    width: '100%',
    paddingBottom: '20px',
    display: 'flex',
    justifyContent: 'center'
  },
  listButton: {
    textAlign: 'left',
    color: 'rgba(0, 0, 0, 0.87)!important'
  }
}));

export default function ListSection(props) {
  const classes = useStyles();

  return (
    <div className={classes.listSectionContainer}>
      <ul>
      {
        props.list.map((item, index) => (
          <li>
            <Button className={classes.listButton} href={item.link1} target='_blank' disabled={ item.link1 == '' ? true : false }>
              { item.name }
            </Button>
          </li>
        ))
      }
      </ul>
    </div>
  );
}
