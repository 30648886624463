import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

import hero from '../../../images/aiD-hospital.png';


const useStyles = makeStyles((theme) => ({
  deptIntroContainer: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap'
  },
  head: theme.deptProjectsHeader,
  deptIntroHeroContain:{
    width: '100%',
    maxHeight: '480px',
    overflowY: 'hidden'
  }
}));

export default function DepartmentIntro(props) {
  const classes = useStyles();
  const header = props.name + " (" + props.acronym + ")";

  return (
    <div className={classes.deptIntroContainer}>
      <div className={classes.head}>
        <Typography
          variant='h3'
          color='primary' >
          { header }
        </Typography>
      </div>
      <div className={classes.deptIntroHeroContain}>
        <img src={hero} width='100%' height='auto'/>
      </div>
    </div>
  );
}
