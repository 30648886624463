import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles((theme) => ({
  deptAboutContainer: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap'
  },
  deptAboutContent: {
    textAlign: 'left'
  }
}));

export default function DepartmentAbout(props) {
  const classes = useStyles();

  return (
    <div className={classes.deptAboutContainer}>
      <Typography
        variant='h4'
        color='primary' >
        About
      </Typography>
      <div className={classes.deptAboutContent}>
        { props.content }
      </div>
    </div>
  );
}
