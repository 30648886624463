import React, { } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles((theme) => ({
  aboutSectionContainer: {
    width: '100%',
    paddingBottom: '20px',
    display: 'flex',
    justifyContent: 'center'
  },
  blockQuote: {
    padding: '40px',
    fontStyle: 'italic',
    lineHeight: 1.7,
    backgroundColor: '#0092d614',
  },
  aboutCredit: {
    width: '100%',
    textAlign: 'right'
  }
}));

export default function AboutSection(props) {
  const classes = useStyles();

  return (
    <div className={classes.aboutSectionContainer}>
      <div className={classes.blockQuote}>
        <Typography
          variant='h6'
          color='primary'
          gutterBottom={true}>
          About
        </Typography>
        {props.about}
        <div className={classes.aboutCredit}><a href={props.link} target='_blank'>{props.text}</a></div>
      </div>
    </div>
  );
}
