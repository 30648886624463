import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import SearchResult from './SearchResult.js';

import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  resultsSection: {
    width: '90%',
    maxWidth: '1000px',
    display: 'flex',
    flexWrap: 'wrap'
  },
  resultsSectionHead: {
    width: '180px'
  },
  resultsSectionItems: {
    width: 'calc(100% - 185px)',
    minWidth: '300px'
  },
  mainHr: {
    width: '100%',
    height: '0px',
    marginTop: 10,
    marginBottom: 10,
    borderTop: '2px solid #0092d6',
    borderRadius: '1px'
  },
}));

export default function SearchResultsSection(props) {
  const classes = useStyles();

  return (
    <div className={classes.resultsSection}>
      <div className={classes.mainHr} />
      <div className={classes.resultsSectionHead}>
        <Typography variant="h5" color="primary" id='search-forms'>{ props.name }</Typography>
      </div>
      <div className={classes.resultsSectionItems}>
        {props.set.map((item, index) => (
            <SearchResult result={item} />
          ))}
      </div>
    </div>
  );
}
