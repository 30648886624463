import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ReactGA from 'react-ga';
import { useLocation } from "react-router-dom";

import NsurpPage from '../layout/nsurp/NsurpPage';

const useStyles = makeStyles((theme) => ({
  nsurpRoot: {
    minHeight: '100vh',
    width: '100%',
    position: 'relative',
    overflow: 'hidden'
  },
}));

export default function Nsurp() {
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return (
    <div className={classes.nsurpRoot}>
      <NsurpPage
        name='Nemours Summer Undergraduate Research Program'
        acronym='NSURP'
        />
    </div>
  );
}
