import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import ResourceCard from './ResourceCard';

import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  resourceSection: {
    width: '100%',
    padding: '20px',
    display: 'block'
  },
  resourceSectionHead: {
    width: '100%',
  },
  resourceCardSection: {
    width: '100%',
    padding: '20px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  resourceCardContain: {
    width: '310px',
    height: '200px',
    padding: '10px'
  },
}));

export default function ResourceSection(props) {
  const classes = useStyles();

  return (
    <div className={classes.resourceSection}>
      <div className={classes.resourceSectionHead}>
        <Typography
          variant='h4'
          color='primary' >
          { props.name }
        </Typography>
      </div>
      <div className={classes.resourceCardSection}>
      {
        props.items.map((resource, index) => (
          <div key={index} className={classes.resourceCardContain}>
            <ResourceCard
              name={resource.name}
              description={resource.description}
              link={resource.link}
              tab={resource.newTab}
              download={resource.download} />
          </div>
        ))
      }
      </div>
    </div>
  );
}
