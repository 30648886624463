import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  linkBackground: {
    backgroundColor: '#ffffff',
    borderRadius: '4px',
  },
  linkButton: {
    display: 'block',
    height: '190px',
    padding: '10px',
    textTransform: 'none',
    backgroundColor: '#00426a14',
  }
}));

export default function HomeLink(props) {
  const classes = useStyles();
  var internal = '';

  if (props.internal === true){
    internal = (
      <div className='internal-indicator-contain'>
        <div className='internal-indicator'>
          Internal
        </div>
      </div>
    );
  }

  return (
    <div className={classes.linkBackground}>
      <Button
        variant='contained'
        className={classes.linkButton}
        target="_blank"
        href={props.link}
        fullWidth>
        <div className='link-button-container'>
          { props.icon }
          <Typography color='primary' variant="h5" component="h2">
            { props.name }
          </Typography>
          <Typography color='secondary' component="p">
            { props.description }
          </Typography>
          { internal }
        </div>
      </Button>
    </div>

  );
}
