import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles((theme) => ({
  contactContainer: {
    width: '100%',
  },
  contactContent: {
    textAlign: 'left',
    padding: '20px',
  },
  infoParagraph: {
    marginBottom: '20px',
  },
}));

export default function Contact(props) {
  const classes = useStyles();

  return (
    <div className={classes.contactContainer}>
      <Typography
        variant='h2'
        color='primary' >
        Contact
      </Typography>
      <div className={classes.contactContent}>
        <Typography
          variant='h4'
          color='primary' >
          Email
        </Typography>
        <Typography
          variant='body1'
          color='secondary' >
          Please send an email message to the Program Coordinator at <a href="mailto:summerresearch@nemours.org">summerresearch@nemours.org</a>.
        </Typography>
      </div>
    </div>
  );
}
