import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import HomeSearchResults from './HomeSearchResults.js';

import { Grid, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
  homeSearchContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap'
  },
  searchBarContain: {
    width: '70%',
    maxWidth: '450px'
  },
  iconContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  searchInput: {
    fontSize: '34px'
  },
  searchResultsContain: {
    width: '100%'
  }
}));

export default function HomeSearch(props) {
  const classes = useStyles();
  const [view, setView] = useState(0);
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [linkResults, setLinkResults] = useState([]);
  const [formResults, setFormResults] = useState([]);
  const [deptResults, setDeptResults] = useState([]);
  var resContent = '';

  // Filter search results
  const searchFilter = (value, set) => {
    var newResults = [];
    newResults = set.filter(item => (
      (item.name.toLowerCase().indexOf(value.toLowerCase()) > -1 || item.description.toLowerCase().indexOf(value.toLowerCase()) > -1 || item.link.toLowerCase().indexOf(value.toLowerCase()) > -1)
    ));
    return newResults;
  }

  // Handle search action
  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    if (e.target.value !== ''){
      // Filter links
      let linkRes = searchFilter(e.target.value, props.links);
      setLinkResults(linkRes);
      // Filter forms
      let formRes = searchFilter(e.target.value, props.forms);
      setFormResults(formRes);
      // Filter departments
      let deptRes = searchFilter(e.target.value, props.departments);
      setDeptResults(deptRes);
      // Set results open
      setOpen(true);
    } else {
      setLinkResults([]);
      setFormResults([]);
      setDeptResults([]);
      setOpen(false);
    }
  }

  // Display results if search in progress
  if (searchValue !== ''){
    resContent = (
      <HomeSearchResults
        links={linkResults}
        forms={formResults}
        departments={deptResults}
        open={open} />
    );
  }
  return (
    <div className={classes.homeSearchContainer}>
      <div className={classes.searchBarContain}>
        <Grid container spacing={1} alignItems="flex-end">
          <Grid item xs={2}>
            <div className={classes.iconContainer}>
              <SearchIcon color='action' fontSize='large'/>
            </div>
          </Grid>
          <Grid item xs={10}>
            <TextField
              id="home-search"
              label="Search"
              value={searchValue}
              onChange={(e) => handleSearch(e)}
              InputProps={{
                classes: {
                  input: classes.searchInput,
                },
              }}
              InputLabelProps={{
                classes: {
                  root: classes.searchInput,
                },
              }}
              fullWidth/>
          </Grid>
        </Grid>
      </div>
      <div className={classes.searchResultsContain}>
        { resContent }
      </div>
    </div>
  );
}
