import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import SearchResultsSection from '../search/SearchResultsSection.js';

import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  searchResultsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '30px'
  },
}));

export default function ResourceSearchResults(props) {
  const classes = useStyles();
  var title = 'No results';
  var r1 = '';
  var r2 = '';
  var r3 = '';

  if (props.res1.length >= 1 || props.res2.length >= 1 || props.res3.length >= 1){
    title = 'Results';
  }

  if (props.res1.length >= 1){
    r1 = (
      <SearchResultsSection
        name={props.name1}
        open={props.open}
        set={props.res1}/>
    );
  }

  if (props.res2.length >= 1){
    r2 = (
      <SearchResultsSection
        name={props.name2}
        open={props.open}
        set={props.res2}/>
    );
  }

  if (props.res3.length >= 1){
    r3 = (
      <SearchResultsSection
        name={props.name3}
        open={props.open}
        set={props.res3}/>
    );
  }


  return (

      <div className={classes.searchResultsContainer}>
        <Typography variant="h3" color="primary" id='search-results'>
          { title }
        </Typography>
        { r1 }
        { r2 }
        { r3 }
      </div>
  );
}
