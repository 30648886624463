import React, {  } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles((theme) => ({
  footerRoot: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px 50px',
  },
  footerText: {
    marginBottom: 0,
  },
}));

export default function Footer(props) {
  const classes = useStyles();

  return (
    <div className={classes.footerRoot}>
      <Typography
        className={classes.footerText}
        variant='body2'
        color='secondary'
        align='center'
      >
        ©2022 The Nemours Foundation. ®Nemours Children's Health is a registered trademark of The Nemours Foundation.
      </Typography>
    </div>

  );
}
