import React, { useState, useEffect } from 'react';
import './App.css';
import theme from './Theme';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ReactGA from 'react-ga';

import { ThemeProvider } from '@material-ui/styles';
import NavBar from './layout/general/NavBar.js';
import Home from './pages/Home.js';
import Bric from './pages/BRIC.js';
import NSURP from './pages/NSURP.js';

// TEST
// ReactGA.initialize('UA-186236522-1');
// PROD
ReactGA.initialize('UA-186236522-2');


function App() {
  const [homeClass, setHomeClass] = useState('App Home');

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <div className={homeClass}>
          <NavBar setHomeClass={setHomeClass} />
          <Switch>
            <Route exact path='/' component={Home} />
            <Route exact path='/bric' component={Bric} />
            <Route path='/SummerResearch' component={() => {
              window.location.replace('https://www.nemours.org/pediatric-research/education.html#tabs-89e351a1be-item-9e11886a8e-tab')
            }} />
          </Switch>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
